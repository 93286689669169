.select2-lg {
	.select2-container .select2-selection--single {
		height: 2.895rem ;
	}
	.select2-container--default .select2-selection--single .select2-selection__rendered {
		line-height: 35px !important;
	}
}

.select2-sm {
	.select2-container .select2-selection--single {
		height: 1.775rem ;
	}
	.select2-container--default .select2-selection--single {
		.select2-selection__rendered {
			line-height: 20px !important;
		}
	}
}

select.form-control {
	&.select-lg {
		height: 46px ;
		padding: 10px 16px ;
		font-size: 17px ;
		line-height: 1.3333333 ;
		border-radius: 3px ;
	}
	&.select-sm {
		height: 30px ;
		padding: 0px 10px 1px 10px ;
		line-height: 1 ;
		border-radius: 3px ;
	}
}

.select2-container--default {
	.select2-selection--single {
		background-color: $white;
		border: 1px solid $border ;
		border-radius: 4px;
	}
	&.select2-container--focus .select2-selection--multiple {
		color: $color;
		background-color: $white;
		border-color: $border;
		outline: 0;
	}
	.select2-selection--multiple {
		background-color: $white;
		border: 1px solid $border ;
		border-radius: 4px;
		cursor: text;
	}
	.select2-search--dropdown .select2-search__field {
		border: 1px solid $border ;
	}
	.select2-results__option--highlighted[aria-selected] {
		background-color: $background;
	}
	.select2-results__option[aria-selected=true] {
		background-color: $background ;
	}
	.select2-selection--multiple .select2-selection__choice {
		background-color: $background ;
		border: 1px solid $border ;
	}
}

.select2-container--default .select2-selection--single .select2-selection__rendered {
	line-height: 39px !important;
	color: $color ;
}

.select2.select2-container.select2-container--default.select2-container--below.select2-container--open {
	width: 100%;
}

.select-languages,
.select-currency {
	.select2-container .select2-selection--single {
		height: 1.4rem ;
		border-radius: 3px;
		background: 0 0 ;
		border: 0 ;
	}
	.select2-container--default .select2-selection--single {
		.select2-selection__rendered {
			line-height: 20.5px ;
		}
		.select2-selection__arrow {
			top: -2px ;
		}
	}
	.select2-container {
		width: 138px ;
	}
}

.select-country {
	.select2-container {
		.select2-selection--single {
			.select2-selection__rendered {
				padding-left: 3px;
			}
			height: 1.4rem ;
			border-radius: 3px;
			background: 0 0 ;
			border: 0 ;
		}
		width: 150px ;
	}
	.select2-container--default .select2-selection--single {
		.select2-selection__rendered {
			line-height: 21px ;
		}
		.select2-selection__arrow {
			top: -3px ;
		}
	}
	.select2-container .select2-selection--single .select2-selection__rendered {
		padding-left: 3px;
	}
}

.select2-results__option {
	font-size: 13px;
}

.select2-lg {
	.select2-container--default .select2-selection--single .select2-selection__arrow {
		top: 12px;
	}
}

.top-bar {
	.select2-container--default .select2-selection--single .select2-selection__rendered {
		line-height: 21px !important;
	}
}
.search-background.bg-transparent {
	.select2-lg .select2-container--default .select2-selection--single .select2-selection__rendered {
		line-height: 47px !important;
		font-size: 15px;
	}
}
.userprof-tab {
	.select2-sm .select2-container--default .select2-selection--single .select2-selection__rendered {
		line-height: 30px !important;
	}
}