
html[dir=rtl] {
    direction: rtl;
}

body.rtl {
    direction: rtl;
    text-align: right;
}

.rtl {

    dd {
        margin-right: 0;
        margin-left: inherit;
    }

    caption {
        text-align: right;
    }

    @media (min-width: 768px) {
        .item-card7-desc ul li:last-child {
            margin-left: 0;
            margin-right: inherit;
        }
    }

    .alert-dismissible {
        padding-left: 3.90625rem;
        padding-right: inherit;
    }

    .alert-dismissible .close {
        left: 0;
        right: inherit;
    }

    .alert-success hr.message-inner-separator {
        background-image: -webkit-linear-gradient(right, #fff, #fff, #fff);
    }

    .alert-info hr.message-inner-separator {
        background-image: -webkit-linear-gradient(right, #fff, #fff, #fff);
    }

    .alert-warning hr.message-inner-separator {
        background-image: -webkit-linear-gradient(right, #6f6f6f, #6f6f6f, #6f6f6f);
    }

    .alert-danger hr.message-inner-separator {
        background-image: -webkit-linear-gradient(right, #fff, #fff, #fff);
    }

    .alert-light hr.message-inner-separator {
        background-image: -webkit-linear-gradient(right, #fff, #fff, #fff);
    }

    .alert-dark hr.message-inner-separator {
        background-image: -webkit-linear-gradient(right, #fff, #fff, #fff);
    }

    .alert-icon {
        padding-right: 3rem;
        padding-left: inherit;
    }

    .alert-icon>i {
        right: 1rem;
        left: inherit;
    }

    .alert-avatar {
        padding-right: 3.75rem;
        padding-left: inherit;
    }

    .alert-avatar .avatar {
        right: 0.75rem;
        left: inherit;
    }

    .avatar-status {
        left: -2px;
        right: inherit;
    }

    .avatar-list .avatar:not(:last-child) {
        margin-left: 0.5rem;
        margin-right: inherit;
    }

    .avatar-list-stacked .avatar {
        margin-left: -0.8em !important;
        margin-right: inherit !important;
    }

    .btn .badge {
        margin-right: 2px;
        margin-left: inherit;
    }

    .badgetext {
        float: left;
    }

    .badge-offer {
        right: 15px;
        left: inherit;
    }

    .badge-offer.bg-danger:after {
        right: 0;
        left: inherit;
    }

    .badge-offer.bg-primary:after {
        right: 0;
        left: inherit;
    }

    .badge-offer.bg-secondary:after {
        right: 0;
        left: inherit;
    }

    .badge-offer.bg-success:after {
        right: 0;
        left: inherit;
    }

    .badge-offer.bg-info:after {
        right: 0;
        left: inherit;
    }

    .badge-offer.bg-warning:after {
        right: 0;
        left: inherit;
    }

    .badge-offer.bg-purple:after {
        right: 0;
        left: inherit;
    }

    .badge-offer.bg-pink:after {
        right: 0;
        left: inherit;
    }

    .badge-offer1 {
        left: 15px;
        right: inherit;
    }

    .badge-offer1.bg-danger:after {
        right: 0;
        left: inherit;
    }

    .badge-offer1.bg-primary:after {
        right: 0;
        left: inherit;
    }

    .badge-offer1.bg-secondary:after {
        right: 0;
        left: inherit;
    }

    .badge-offer1.bg-success:after {
        right: 0;
        left: inherit;
    }

    .badge-offer1.bg-info:after {
        right: 0;
        left: inherit;
    }

    .badge-offer1.bg-warning:after {
        right: 0;
        left: inherit;
    }

    .badge-offer1.bg-purple:after {
        right: 0;
        left: inherit;
    }

    .badge-offer1.bg-pink:after {
        right: 0;
        left: inherit;
    }

    .offer .shape {
        border-width: 0 0 40px 70px;
        float: left;
        left: 0;
        right: auto;
        border-color: rgba(255, 255, 255, 0) rgba(255, 255, 255, 0) rgba(255, 255, 255, 0) #ef5050;
    }

    .offer-danger .shape {
        border-color: transparent transparent transparent #f84242;
    }

    .offer-success .shape {
        border-color: transparent transparent transparent #21b632;
    }

    .offer-default .shape {
        border-color: transparent transparent transparent #18113c;
    }

    .offer-info .shape {
        border-color: transparent transparent transparent #04b2e5;
    }

    .offer-warning .shape {
        border-color: transparent transparent transparent #fb9505;
    }

    .offer-primary .shape {
        border-color: transparent transparent transparent $primary;
    }

    .shape-text {
        left: -47px;
        right: inherit;
        transform: rotate(-30deg);
    }

    .breadcrumb {
        right: 0;
        left: inherit;
    }

    .breadcrumb-1 ol,
    .breadcrumb-2 ol {
        text-align: left;
    }

    .breadcrumb-1 li,
    .breadcrumb-2 li {
        text-align: left;
    }

    .page-header .breadcrumb-item+.breadcrumb-item {
        padding-right: 0;
        padding-left: inherit;
    }

    .btn.btn-app {
        margin: 0 10px 10px 0;
    }

    .btn.btn-app>.badge {
        left: -10px;
        right: inherit;
    }

    .btn-list>.btn:not(:last-child),
    .btn-list>.dropdown:not(:last-child) {
        margin-left: 0.5rem;
        margin-right: inherit;
    }

    .btn-loading:after {
        border-left-color: transparent !important;
        border-right-color: inherit !important;
        right: calc(50% - 0.7em);
        left: inherit;
    }

    .btn-group-sm>.btn-loading.btn:after {
        right: calc(50% - 0.5em);
        left: inherit;
    }

    .btn-loading.btn-sm:after {
        left: calc(50% - 0.5em);
    }

    .btn-group .btn+.btn,
    .btn-group .btn+.btn-group {
        margin-right: -1px;
        margin-left: initial;
    }

    .btn-group .btn-group+.btn,
    .btn-group .btn-group+.btn-group {
        margin-right: -1px;
        margin-left: inherit;
    }

    .btn-group-vertical .btn+.btn,
    .btn-group-vertical .btn+.btn-group {
        margin-right: -1px;
        margin-left: inherit;
    }

    .btn-group-vertical .btn-group+.btn,
    .btn-group-vertical .btn-group+.btn-group {
        margin-right: -1px;
        margin-left: inherit;
    }

    .btn-group>.btn:first-child {
        margin-right: 0;
        margin-left: initial;
    }

    .btn-group > .btn-group:not(:first-child) > .btn, 
    .btn-group > .btn:not(:first-child) {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        border-top-left-radius: 7px;
        border-bottom-left-radius: 7px;
    }

    .btn-group>.btn-group:not(:last-child)>.btn,
    .btn-group>.btn:not(:last-child):not(.dropdown-toggle) {
        border-top-left-radius: 0;
        border-top-right-radius: 7px;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 7px;
    }

    .btn-list.checkboxbtns .btn-group > .btn:nth-child(n+3), 
    .btn-list.radiobtns .btn-group > .btn:nth-child(n+3) {
        border-top-right-radius: 0 !important;
        border-bottom-right-radius: 0 !important;
    }

    .btn-toolbar .btn-group>.btn:nth-child(n+2) {
        border-top-right-radius: 0 !important;
        border-bottom-right-radius: 0 !important;
    }

    .btn-group-vertical>.btn+.btn,
    .btn-group-vertical>.btn+.btn-group {
        margin-right: 0;
        margin-left: inherit;
    }

    .btn-group-vertical>.btn-group+.btn,
    .btn-group-vertical>.btn-group+.btn-group {
        margin-right: 0;
        margin-left: inherit;
    }

    .btn-animation .btn-loaders {
        padding-left: 46px;
        padding-right: inherit;
    }

    .btn-animation .btn-loaders:after {
        border-left-color: transparent !important;
        border-right-color: inherit !important;
        left: 20px !important;
        right: inherit !important;
    }

    .card-link+.card-link {
        margin-right: 1.5rem;
        margin-left: inherit;
    }

    @media (min-width: 576px) {

        .card-group>.card+.card {
            margin-right: 0;
            margin-left: inherit;
            border-right: 0;
            border-left: inherit;
        }

        .card-group>.card:first-child {
            border-top-left-radius: 0;
            border-top-right-radius: inherit;
            border-bottom-left-radius: 0;
            border-bottom-right-radius: inherit;
        }

        .card-group>.card:first-child .card-header,
        .card-group>.card:first-child .card-img-top {
            border-top-left-radius: 0;
            border-top-right-radius: inherit;
        }

        .card-group>.card:first-child .card-footer,
        .card-group>.card:first-child .card-img-bottom {
            border-bottom-left-radius: 0;
            border-bottom-right-radius: inherit;
        }

        .card-group>.card:last-child {
            border-top-right-radius: 0;
            border-top-left-radius: inherit;
            border-bottom-right-radius: 0;
            border-bottom-left-radius: inherit;
        }

        .card-group>.card:last-child .card-header,
        .card-group>.card:last-child .card-img-top {
            border-top-right-radius: 0;
            border-top-left-radius: inherit;
        }

        .card-group>.card:last-child .card-footer,
        .card-group>.card:last-child .card-img-bottom {
            border-bottom-right-radius: 0;
            border-bottom-left-radius: inherit;
        }
    }

    .card-blog-overlay1:before,
    .card-blog-overlay2:before,
    .card-blog-overlay:before {
        left: 0;
        right: inherit;
    }

    .card-table tr td:first-child,
    .card-table tr th:first-child {
        padding-right: 1.5rem;
        padding-left: inherit;
    }

    .card-table tr td:last-child,
    .card-table tr th:last-child {
        padding-left: 1.5rem;
        padding-right: 0.75rem;
    }

    .card-link+.card-link {
        margin-right: 1rem;
        margin-left: inherit;
    }

    .card-options {
        margin-right: auto;
        margin-left: -0.5rem;
    }

    .card-options a:not(.btn) {
        margin-right: 0.5rem;
        margin-left: auto;
    }

    .card-img-absolute {
        left: 0;
        right: inherit;
    }

    .card-status-left {
        left: auto;
        right: inherit;
        border-radius: 0 7px 7px 0;
    }

    .card-aside-img a {
        right: 0;
        left: inherit;
    }

    .card-img-overlay1 .widgetbox {
        right: 0;
        left: inherit;
    }

    .card-blog-overlay6:before {
        left: 0;
        right: inherit;
    }

    .card-blog-img:before {
        left: 0;
        right: inherit;
    }

    .card-pay .tabs-menu li a {
        border-left: 1px solid $border;
        border-right: inherit;
    }

    .card-video:before {
        left: 0;
        right: inherit;
    }

    .carousel-indicators {
        padding-right: 0;
        padding-left: inherit;
    }

    .carousel-indicators li::before {
        right: 0;
        left: inherit;
    }

    .carousel-indicators li::after {
        right: 0;
        left: inherit;
    }

    .carousel-indicators1.carousel-indicators {
        padding-right: 0;
        padding-left: inherit;
    }

    .carousel-indicators1 li::before {
        right: 0;
        left: inherit;
    }

    .carousel-indicators1 li::after {
        right: 0;
        left: inherit;
    }

    .carousel-indicators2.carousel-indicators {
        left: 0px;
        right: auto;
        padding-right: 0;
        padding-left: inherit;
    }

    .carousel-indicators2 li::before {
        right: 0;
        left: inherit;
    }

    .carousel-indicators2 li::after {
        right: 0;
        left: inherit;
    }

    .carousel-indicators3.carousel-indicators {
        right: 0px;
        left: auto;
        padding-right: 0;
        padding-left: inherit;
    }

    .carousel-indicators3 li::before {
        right: 0;
        left: inherit;
    }

    .carousel-indicators3 li::after {
        right: 0;
        left: inherit;
    }

    .carousel-indicators4.carousel-indicators {
        left: 0px;
        right: auto;
        padding-right: 0;
        padding-left: inherit;
    }

    .carousel-indicators4 li::before {
        right: 0;
        left: inherit;
    }

    .carousel-indicators4 li::after {
        right: 0;
        left: inherit;
    }

    .carousel-indicators5.carousel-indicators {
        right: 0px;
        left: auto;
        padding-right: 0;
        padding-left: inherit;
    }

    .carousel-indicators5 li::before {
        right: 0;
        left: inherit;
    }

    .carousel-indicators5 li::after {
        right: 0;
        left: inherit;
    }

    .carousel-control-next {
        left: -1.8rem;
        right: inherit;
    }

    .carousel-control-prev {
        right: -1.8rem;
        left: inherit;
    }

    .dropdown-toggle::after {
        margin-right: 0.255em;
        margin-left: inherit;
    }

    .dropdown-toggle:empty::after {
        margin-right: 0;
        margin-left: inherit;
    }

    .dropdown-menu {
        right: auto;
        left: 0;
        float: right;
        text-align: right;
    }

    .dropdown-menu-start {
        // --bs-position: end;
        right: 0 !important;
        left: auto !important;
    }
    .dropdown-menu-end {
        --bs-position: start;
    }

    .dropdown-menu[x-placement^=bottom],
    .dropdown-menu[x-placement^=left],
    .dropdown-menu[x-placement^=right],
    .dropdown-menu[x-placement^=top] {
        left: auto;
        right: inherit;
    }

    .dropup .dropdown-toggle::after {
        margin-right: 0.255em;
        margin-left: inherit;
    }

    .dropup .dropdown-toggle:empty::after {
        margin-right: 0;
        margin-left: inherit;
    }

    .dropend .dropdown-menu {
        left: auto;
        right: 100%;
        margin-right: 0.125rem;
        margin-left: inherit;
    }

    .dropend .dropdown-toggle::after {
        margin-right: 0.255em;
        margin-left: inherit;
        border-left: 0;
        border-right: 0.3em solid;
    }

    .dropend .dropdown-toggle:empty::after {
        margin-right: 0;
        margin-left: inherit;
    }

    .dropstart .dropdown-menu {
        left: 100%;
        right: auto;
        margin-left: 0.125rem;
        margin-right: inherit;
    }

    .dropstart .dropdown-toggle::after {
        margin-right: 0.255em;
        margin-left: inherit;
    }

    .dropstart .dropdown-toggle::before {
        margin-left: 0.255em;
        margin-right: inherit;
        border-left: 0.3em solid;
        border-right: inherit;
    }

    .dropstart .dropdown-toggle:empty::after {
        margin-right: 0;
        margin-left: inherit;
    }

    .dropdown-toggle-split::after {
        margin-right: 0;
        margin-left: inherit;
    }

    .dropend .dropdown-toggle-split::after,
    .dropup .dropdown-toggle-split::after {
        margin-right: 0;
        margin-left: inherit;
    }

    .dropstart .dropdown-toggle-split::before {
        margin-left: 0;
        margin-right: inherit;
    }

    .dropdown-media-list .media-body {
        margin-right: 15px;
        margin-left: inherit;
    }

    .dropdown-menu-arrow:before {
        right: 12px;
        left: inherit;
    }

    .dropdown-menu-arrow:after {
        right: 12px;
        left: inherit;
    }

    .dropdown-menu-arrow.dropdown-menu-end:after,
    .dropdown-menu-arrow.dropdown-menu-end:before {
        right: auto;
        left: 12px;
    }

    .dropdown-toggle:empty:after {
        margin-right: 0;
        margin-left: inherit;
    }

    .form-checkbox {
        padding-right: 1.25rem;
        padding-left: inherit;
    }

    .form-checkbox-input {
        margin-right: -1.25rem;
        margin-left: inherit;
    }

    .form-checkbox-inline {
        padding-right: 0;
        padding-left: inherit;
        margin-left: 0.75rem;
        margin-right: inherit;
    }

    .form-checkbox-inline .form-checkbox-input {
        margin-left: 0.3125rem;
        margin-right: 0;
    }

    @media (min-width: 576px) {
        .form-inline .form-checkbox {
            padding-right: 0;
            padding-left: inherit;
        }

        .form-inline .form-checkbox-input {
            margin-left: 0.25rem;
            margin-right: 0;
        }
    }

    .form-select {
        padding: 0.5rem 0.75rem 0.5rem 1.75rem;
    }

    .form-select[multiple],
    .form-select[size]:not([size="1"]) {
        padding-left: 0.75rem;
        padding-right: inherit;
    }

    .form-file-label::after {
        left: 0;
        right: inherit;
        border-radius: 7px 0 0 7px;
    }

    .form-label-small {
        float: left;
    }

    .input-icon .form-control:not(:last-child),
    .input-icon .form-control:not(:first-child) {
        padding-right: 2.5rem;
        padding-left: inherit;
    }

    .input-icon-addon {
        right: 0;
        left: inherit;
    }

    .input-icon-addon:last-child {
        right: auto;
        left: 0;
    }

    .state-valid {
        padding-left: 2rem;
        padding-right: .75rem;
    }

    .state-invalid {
        padding-left: 2rem;
        padding-right: .75rem;
    }

    .richText .richText-toolbar ul li a {
        border-left: $border solid 1px;
        border-right: inherit;
    }

    .form .btn {
        left: 0px;
        right: inherit;
    }

    .input-group>.form-file+.form-file,
    .input-group>.form-file+.form-select,
    .input-group>.form-file+.form-control {
        margin-right: -1px;
        margin-left: inherit;
    }

    .input-group>.form-select+.form-file,
    .input-group>.form-select+.form-select,
    .input-group>.form-select+.form-control {
        margin-right: -1px;
        margin-left: inherit;
    }

    .input-group>.form-control+.form-file,
    .input-group>.form-control+.form-select,
    .input-group>.form-control+.form-control {
        margin-right: -1px;
        margin-left: inherit;
    }

    .input-group>.form-select:not(:last-child),
    .input-group>.form-control:not(:last-child) {
        border-top-left-radius: 0;
        border-top-right-radius: 7px;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 7px;
    }

    .input-group>.form-select:not(:first-child),
    .input-group>.form-control:not(:first-child) {
        border-top-right-radius: 0;
        border-top-left-radius: 7px;
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 7px;
    }

    .input-group>.form-file:not(:last-child) .form-file-label {
        border-top-left-radius: 0;
        border-top-right-radius: inherit;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: inherit;
    }

    .input-group>.form-file:not(:last-child) .form-file-label::after {
        border-top-left-radius: 0;
        border-top-right-radius: inherit;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: inherit;
    }

    .input-group>.form-file:not(:first-child) .form-file-label {
        border-top-right-radius: 0;
        border-top-left-radius: inherit;
        border-bottom-right-radius: 0;
        border-bottom-left-radius: inherit;
    }

    .input-group>.form-file:not(:first-child) .form-file-label::after {
        border-top-right-radius: 0;
        border-top-left-radius: inherit;
        border-bottom-right-radius: 0;
        border-bottom-left-radius: inherit;
    }

    .input-group-text .btn+.btn,
    .input-group-text .btn+.input-group-text {
        margin-right: -1px;
        margin-left: inherit;
    }

    .input-group-text .input-group-text+.btn,
    .input-group-text .input-group-text+.input-group-text {
        margin-right: -1px;
        margin-left: inherit;
    }

    .input-group>.input-group-text:last-child>.btn:not(:last-child):not(.dropdown-toggle),
    .input-group>.input-group-text:last-child>.input-group-text:not(:last-child) {
        border-top-left-radius: 0;
        border-top-right-radius: inherit;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: inherit;
    }

    .input-group>.input-group-text:not(:last-child)>.btn,
    .input-group>.input-group-text:not(:last-child)>.input-group-text {
        border-top-left-radius: 0;
        border-top-right-radius: inherit;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: inherit;
    }

    .input-group>.input-group-text:first-child>.btn:not(:first-child),
    .input-group>.input-group-text:first-child>.input-group-text:not(:first-child) {
        border-top-right-radius: 0;
        border-top-left-radius: inherit;
        border-bottom-right-radius: 0;
        border-bottom-left-radius: inherit;
    }

    .input-group>.input-group-text:not(:first-child)>.btn,
    .input-group>.input-group-text:not(:first-child)>.input-group-text {
        border-top-right-radius: 0;
        border-top-left-radius: inherit;
        border-bottom-right-radius: 0;
        border-bottom-left-radius: inherit;
    }

    .input-group-text>.input-group-text {
        border-left: 0;
        border-right: inherit;
    }

    .input-group-text>.input-group-text {
        border-right: 0;
        border-left: inherit;
    }

    .modal-backdrop.fade.show {
        padding-left: 0 !important;
        padding-right: inherit !important;
    }

    .modal:before {
        margin-left: -4px;
        margin-right: inherit;
    }

    .modal-dialog {
        text-align: right;
    }

    .modal-header .close.btn {
        float: left;
        left: 15px;
        right: inherit;
        margin: 0;
    }

    .modal-footer> :not(:first-child) {
        margin-right: 0.25rem;
        margin-left: inherit;
    }

    .modal-footer> :not(:last-child) {
        margin-left: 0.25rem;
        margin-right: inherit;
    }

    .modal.fade.show {
        padding-left: 0 !important;
        padding-right: inherit !important;
    }

    .modal-header .close {
        margin: -1rem auto -1rem -1rem;
    }

    .nav-tabs .nav-item1 i {
        margin-left: 10px;
        margin-right: inherit;
    }

    .nav-unread {
        left: 0.4rem;
        right: inherit;
    }

    .nav-unread.badge {
        left: 0;
        right: inherit;
    }

    .navtab-wizard li:first-of-type a {
        border-right: none !important;
        border-left: inherit !important;
    }

    .navtab-wizard li:first-of-type a:active,
    .navtab-wizard li:first-of-type a:focus,
    .navtab-wizard li:first-of-type a:hover {
        border-right: none !important;
        border-left: inherit !important;
    }

    .navtab-wizard li:last-of-type a {
        border-left: none !important;
        border-right: inherit !important;
    }

    .navtab-wizard li:last-of-type a:active,
    .navtab-wizard li:last-of-type a:focus,
    .navtab-wizard li:last-of-type a:hover {
        border-left: none !important;
        border-right: inherit !important;
    }

    .navtab-wizard.nav-tabs .nav-link {
        border-right: 1px solid $border;
        border-left: inherit;
    }

    .nav-tabs .nav-item i {
        margin-left: 10px;
        margin-right: inherit;
    }

    .pagination {
        padding-right: 0;
        padding-left: inherit;
    }

    .page-link {
        margin-right: -1px;
        margin-left: inherit;
    }

    .page-item:first-child .page-link {
        margin-right: 0;
        margin-left: inherit;
        border-top-right-radius: 7px;
        border-top-left-radius: inherit;
        border-bottom-right-radius: 7px;
        border-bottom-left-radius: inherit;
    }

    .page-item:last-child .page-link {
        border-top-left-radius: 7px;
        border-top-right-radius: inherit;
        border-bottom-left-radius: 7px;
        border-bottom-right-radius: inherit;
    }

    .pagination-lg .page-item:first-child .page-link {
        border-top-right-radius: 7px;
        border-top-left-radius: inherit;
        border-bottom-right-radius: 7px;
        border-bottom-left-radius: inherit;
    }

    .pagination-lg .page-item:last-child .page-link {
        border-top-left-radius: 7px;
        border-top-right-radius: inherit;
        border-bottom-left-radius: 7px;
        border-bottom-right-radius: inherit;
    }

    .pagination-sm .page-item:first-child .page-link {
        border-top-right-radius: 7px;
        border-top-left-radius: inherit;
        border-bottom-right-radius: 7px;
        border-bottom-left-radius: inherit;
    }

    .pagination-sm .page-item:last-child .page-link {
        border-top-left-radius: 7px;
        border-top-right-radius: inherit;
        border-bottom-left-radius: 7px;
        border-bottom-right-radius: inherit;
    }

    ul.inbox-pagination {
        float: left;
    }

    ul.inbox-pagination li {
        float: right;
    }

    .pagination-pager .page-prev {
        margin-left: auto;
        margin-right: inherit;
    }

    .pagination-pager .page-next {
        margin-right: auto;
        margin-left: inherit;
    }

    .col-sm-4 .pricing-table .table td {
        text-align: right;
    }

    .more-less {
        float: left;
    }

    .panel-title a.accordion-toggle:before {
        padding: 0 0 0 10px;
        float: left;
    }

    .panel-title a.accordion-toggle.collapsed:before {
        padding: 0 0 0 10px;
        float: left;
    }

    .panel-heading1 a.collapsed:before {
        left: 10px;
        right: inherit;
    }

    .panel-heading1 a:before {
        left: 10px;
        right: inherit;
    }

    #bs-collapse .panel-heading1 a:after {
        left: 5px;
        right: inherit;
    }

    #accordion .panel-heading1 a:before {
        left: 5px;
        right: inherit;
    }

    .popover {
        right: 0;
        left: inherit;
        text-align: end;
    }

    .progress-bar-indeterminate:after {
        right: 0;
        left: inherit;
    }

    .progress-bar-indeterminate:before {
        right: 0;
        left: inherit;
    }

    .table-calendar-link:before {
        right: 0.25rem;
        left: inherit;
    }

    .table-header:after {
        margin-right: 0.5rem;
        margin-left: inherit;
    }

    .tag-addon {
        margin: 0 0.5rem 0 -0.5rem;
    }

    .tag-addon:last-child {
        border-top-left-radius: 3px;
        border-top-right-radius: 0;
        border-bottom-left-radius: 3px;
        border-bottom-right-radius: 0;
    }

    .tag-avatar {
        border-radius: 0 3px 3px 0;
        margin: 0 -0.5rem 0 0.5rem;
    }

    .tags>.tag:not(:last-child) {
        margin-left: 0.5rem;
        margin-right: inherit;
    }

    @media (max-width: 767px) {
        #count-down .clock-presenter {
            float: right;
        }

        #count-down .hours_dash {
            border-left: none;
            border-right: inherit;
        }
    }

    @media (max-width: 767px) {
        #count-down1 .clock-presenter {
            float: right;
        }

        #count-down1 .hours_dash {
            border-left: none;
            border-right: inherit;
        }
    }

    .social-box ul li {
        padding: 0 0 0 10px;
        float: right;
    }

    .social-box ul li:last-child {
        padding-right: 10px;
        padding-left: 0;
    }

    .statistics-box.with-icon {
        padding-right: 80px;
        padding-left: inherit;
        text-align: left;
    }

    .statistics-box.with-icon .ico {
        right: 0;
        left: inherit;
    }

    .other .msg {
        border-top-right-radius: 0;
        border-top-left-radius: inherit;
    }

    .other:before {
        left: 0;
        right: 50px;
        border-right-color: transparent;
        border-left-color: inherit;
    }

    .self .msg {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: inherit;
    }

    .self .avatar:after {
        left: 26px;
        right: inherit;
        border-left-color: transparent;
        border-right-color: inherit;
    }

    .msg time {
        float: left;
    }

    .msg time:before {
        margin-left: 4px;
        margin-right: inherit;
    }

    .socials li {
        margin: 0 12px;
        margin-left: 15px;
    }

    .socailicons .fa {
        text-align: left;
        left: -5px;
        right: inherit;
    }

    .background {
        right: 50%;
        left: inherit;
    }

    .overlay {
        right: 0;
        left: inherit;
    }


    @media (min-width: 992px) {
        .masthead {
            float: left;
        }

        .sticky-wrapper.is-sticky .desktoplogo-1 {
            float: right;
        }
    }

    .notifyimg {
        float: right;
        margin-left: 10px;
        margin-right: inherit;
    }

    @media (max-width: 480px) {
        .breadcrumb-item+.breadcrumb-item {
            padding-right: 0;
            padding-left: inherit;
        }
    }

    .close {
        float: left;
    }

    .embed-responsive .embed-responsive-item,
    .embed-responsive embed,
    .embed-responsive iframe,
    .embed-responsive object,
    .embed-responsive video {
        right: 0;
        left: inherit;
    }


    blockquote {
        padding-right: 2rem;
        padding-left: initial;
        border-right: 3px solid rgba(227, 237, 252, 0.5);
        border-left: inherit;
    }

    .blockquote-reverse {
        padding-left: 15px;
        padding-right: 0;
        text-align: left;
        border-left: 3px solid rgba(227, 237, 252, 0.5);
        border-right: 0;
    }

    blockquote.float-right {
        padding-left: 15px;
        padding-right: 0;
        text-align: left;
        border-left: 3px solid rgba(227, 237, 252, 0.5);
        border-right: 0;
    }

    blockquote cite {
        text-align: left;
    }

    @media (min-width: 1600px) {
        body.aside-opened .page {
            margin-left: 22rem;
            margin-right: inherit;
        }
    }

    .page-subtitle {
        left: 59rem;
        right: inherit;
    }

    .page-options {
        margin-right: auto;
        margin-left: inherit;
    }

    .aside {
        left: 0;
        right: inherit;
        border-right: 1px solid $border;
        border-left: inherit;
    }

    @media (max-width: 767px) {
        .shop-dec .col-md-6.pe-0 {
            padding-left: 0.75rem !important;
            padding-right: inherit !important;
        }

        .shop-dec .col-md-6.ps-0 {
            padding-right: 0.75rem !important;
            padding-left: inherit !important;
        }
    }

    @media (max-width: 320px) {
        .fc-toolbar .fc-right {
            float: left;
        }
    }

    .page-total-text {
        margin-left: 1rem;
        margin-right: inherit;
    }

    .reg {
        float: left;
    }


    ul li .legend-dots {
        margin-left: 0.5rem;
        margin-right: inherit;
    }

    .statistics-box {
        padding-right: 80px;
        padding-left: inherit;
        text-align: left;
    }

    .statistics-box .ico {
        right: 0;
        left: inherit;
    }

    .icon-absolute {
        left: 10px;
        right: inherit;
    }

    @-webkit-keyframes indeterminate {
        0% {
            right: -35%;
            left: 100%;
        }

        100%,
        60% {
            right: 100%;
            left: -90%;
        }
    }

    @keyframes indeterminate {
        0% {
            right: -35%;
            left: 100%;
        }

        100%,
        60% {
            right: 100%;
            left: -90%;
        }
    }

    @-webkit-keyframes indeterminate-short {
        0% {
            right: -200%;
            left: 100%;
        }

        100%,
        60% {
            right: 107%;
            left: -8%;
        }
    }

    @keyframes indeterminate-short {
        0% {
            right: -200%;
            left: 100%;
        }

        100%,
        60% {
            right: 107%;
            left: -8%;
        }
    }

    .searching1 i {
        margin-right: 15px;
        margin-left: inherit;
    }

    .search-inline {
        right: 0;
        left: inherit;
    }

    .search-inline.search-visible {
        left: 0;
        right: inherit;
    }

    .search-inline button[type=submit] {
        left: 80px;
        right: inherit;
    }

    .search-inline .form-control {
        right: 0;
        left: inherit;
    }

    .search-inline .search-close {
        left: 0;
        right: inherit;
    }


    .status-icon {
        margin-left: 0.375rem;
        margin-right: inherit;
    }

    .team i {
        margin-right: 10px;
        margin-left: inherit;
        float: left;
    }

    .chips .chip {
        margin: 0 0 0.5rem 0.5rem;
    }

    .chip .avatar {
        float: left;
        margin: 0 -0.75rem 0 0.5rem;
    }

    .colorinput-color:before {
        right: 0.25rem;
        left: inherit;
    }

    #back-to-top {
        left: 20px;
        right: inherit;
    }

    #messages-main .ms-menu {
        right: 0;
        left: inherit;
        border-left: 1px solid $border;
        border-right: inherit;
    }

    #messages-main .ms-user>div {
        padding: 3px 15px 0 5px;
    }

    #messages-main #ms-compose {
        left: 30px;
        right: inherit;
    }

    #ms-menu-trigger {
        right: 0;
        left: inherit;
        padding-left: 10px;
        padding-right: inherit;
    }

    .message-feed.right>.pull-end {
        margin-right: 15px;
        margin-left: inherit;
    }

    .message-feed:not(.right) .mf-content:before {
        right: -20px;
        left: inherit;
    }

    .message-feed.right .mf-content:before {
        border-right-color: $border;
        border-left-color: inherit;
        left: -20px;
        right: inherit;
    }

    .msb-reply {
        border-radius: 7px 0 7px 7px;
    }

    .msb-reply button {
        left: 0;
        right: inherit;
        border-radius: 3px 0 0 3px;
    }

    .action-header {
        padding: 15px 17px 15px 13px;
    }

    .ah-actions {
        float: left;
    }

    #ms-menu-trigger {
        right: 0;
        left: inherit;
        padding-left: 10px;
        padding-right: inherit;
        text-align: left;
    }

    .message-feed.right {
        text-align: left;
    }

    .job-box-filter label input.form-control {
        margin-right: 5px;
        margin-left: inherit;
    }

    .job-box-filter a.filtsec {
        margin-left: 15px;
        margin-right: inherit;
    }

    .job-box-filter a.filtsec i {
        margin-left: 5px;
        margin-right: inherit;
    }

    .job-box-filter h4 i {
        margin-left: 10px;
        margin-right: inherit;
    }

    #index-video video {
        right: 0;
        left: inherit;
    }

    .video-list-thumbs .duration {
        right: 12px;
        left: inherit;
    }

    @media (max-width: 992px) {
        .product-singleinfo .border-start {
            border-right: 0 !important;
            border-left: inherit !important;
        }

        .support .border-end {
            border-left: 0 !important;
            border-right: inherit !important;
        }

        .wideget-user-info .wideget-user-warap .wideget-user-warap-r {
            margin-right: 5rem !important;
            margin-left: inherit !important;
        }

        .wideget-user-desc .user-wrap {
            margin-right: 10px !important;
            margin-left: inherit !important;
        }

        .product-singleinfo .product-item2-align dd {
            margin-right: 0 !important;
            margin-left: inherit !important;
        }

        .product-5-desc a {
            float: left;
        }

        .get-contact a:last-child {
            margin-left: 0;
            margin-right: inherit;
        }

        .header-nav .header-search-logo .header-logo .header-brand-img {
            margin-left: 0.5rem;
            margin-right: inherit;
        }

        .product-item2-desc .label-rating {
            margin-left: 4px !important;
            margin-right: inherit !important;
        }

        .card-blogs .card-item-desc.p-0 {
            margin-right: 15px;
            margin-left: inherit;
        }

        .license-card-price.text-center {
            text-align: right !important;
        }

        .header-main .get-contact a {
            border-right: 0 !important;
            border-left: inherit !important;
            padding-right: 0 !important;
            padding-left: inherit !important;
        }

        .header-main .social-icons li:last-child {
            margin-left: 0;
            margin-right: inherit;
        }

        .top-bar .top-bar-start .contact {
            border-right: 0 !important;
            border-left: inherit !important;
            margin-right: 0 !important;
            margin-left: inherit !important;
            padding-right: 0 !important;
            padding-left: inherit !important;
        }

        .item-user-icons {
            margin-right: 10px;
            margin-left: inherit;
        }

        .course-details .item-user-icons {
            margin-right: 0px;
            margin-left: inherit;
        }

        .carousel-control-next {
            left: 0 !important;
            right: inherit !important;
        }

        .carousel-control-prev {
            right: 0 !important;
            left: inherit !important;
        }

        .userprof-tab .media-body .card-item-desc {
            margin-right: 0 !important;
            margin-left: inherit !important;
        }

        .settings-tab .tabs-menu li a {
            border-left: 0 !important;
            border-right: inherit !important;
        }
    }

    @media (max-width: 568px) {
        .header-main .top-bar-end .custom {
            text-align: left;
            float: left;
        }

        .top-bar .top-bar-start .socials li {
            float: right !important;
        }
    }

    @media (min-width: 481px) and (max-width: 991px) {
        .item-card:hover .item-card-btn {
            right: 42%;
            left: inherit;
        }

        .userprof-tab .media-body .card-item-desc {
            margin-right: 0 !important;
            margin-left: inherit !important;
        }
    }

    @media (min-width: 992px) and (max-width: 1024px) {

        .header-main .get-contact a {
            border-right: 0 !important;
            border-left: inherit !important;
            padding-right: 0 !important;
            padding-left: inherit !important;
        }

        .header-main .social-icons li:last-child {
            margin-left: 0;
            margin-right: inherit;
        }

        .header-search .header-icons .header-icons-link {
            float: left;
        }

        .top-bar .top-bar-start .contact {
            border-right: 0 !important;
            border-left: inherit !important;
            margin-right: 0 !important;
            margin-left: inherit !important;
            padding-right: 0 !important;
            padding-left: inherit !important;
        }

        .top-bar .top-bar-start .contact li:first-child {
            margin-left: 1.5rem !important;
            margin-right: inherit !important;
        }

        .support .border-end {
            border-left: 0 !important;
            border-right: inherit !important;
        }

        .item-search-tabs .tab-content {
            margin: 0px 0 0 5px !important;
        }
    }

    @media (max-width: 480px) {

        .support .support-service i {
            margin-left: 0 !important;
            margin-right: inherit !important;
        }

        .item-det ul li {
            margin-left: 0.5rem !important;
            margin-right: inherit !important;
        }

        .userprof-tab .media-body .card-item-desc {
            margin-right: 0 !important;
            margin-left: inherit !important;
        }
    }

    .trend .media-body {
        margin-right: 10px;
        margin-left: inherit;
    }

    .datebox {
        right: 0;
        left: inherit;
    }

    .iconbage .badge {
        left: 13px;
        right: inherit;
    }

    .product-tags a {
        float: right;
        margin-left: 5px;
        margin-right: inherit;
    }

    .support-service i {
        float: right;
        margin-left: 15px;
        margin-right: inherit;
    }

    .get-contact a {
        margin-left: 15px;
        margin-right: inherit;
    }

    .filter-product-social .social-icon2 li {
        margin-left: 35px;
        margin-right: inherit;
    }

    .product-info-img a {
        left: 30px;
        right: inherit;
    }

    .usertab-list li {
        float: right;
    }

    .info-box-icon {
        float: right;
        border-top-right-radius: 2px;
        border-top-left-radius: inherit;
        border-bottom-right-radius: 2px;
        border-bottom-left-radius: inherit;
    }

    .info-box-content {
        margin-right: 90px;
        margin-left: inherit;
    }

    .product-item span {
        right: 15px;
        left: inherit;
    }

    .product-item-wrap .product-item-price .oldprice {
        margin-right: 5px;
        margin-left: inherit;
    }

    .icon-card li {
        float: right;
    }

    .product-item2-rating {
        margin-left: 10px;
        margin-right: inherit;
    }

    .product-item2-desc .label-rating {
        margin-left: 10px;
        margin-right: inherit;
    }

    .product-details .card-item-desc {
        margin-right: 15px;
        margin-left: inherit;
    }

    .product-singleinfo .product-item2-align dd {
        margin-right: 2rem;
        margin-left: inherit;
    }

    .product-singleinfo .product-item2-align dt {
        float: right;
    }

    .product-singleinfo .product-ship .product-item-price .oldprice {
        margin-right: 5px;
        margin-left: inherit;
    }

    .product-gallery-data .product-gallery-data1 dt {
        float: right;
    }

    .product-gallery-data .product-gallery-data1 dd {
        margin-right: 2rem;
        margin-left: inherit;
    }

    .product-gallery-rating {
        margin-left: 10px;
        margin-right: inherit;
    }

    .product-gallery-data .label-rating {
        margin-left: 10px;
        margin-right: inherit;
    }

    .product-gallery-data .product-gallery-size .product-gallery-checks label {
        margin-right: 10px;
        margin-left: inherit;
    }

    .product-card-footer .product-footer-wrap .footer-wrap-price del {
        margin-right: 5px;
        margin-left: inherit;
    }

    .product-card6-footer .product-card6-info del {
        margin-left: 5px;
        margin-right: inherit;
    }

    .product-card7-footer .product-card7-price del,
    .product-card7-footer .product-card7-price span {
        margin-right: 5px;
        margin-left: inherit;
    }

    .banner1 .carousel-item:before {
        left: 0;
        right: inherit;
    }

    .banner1 .carousel-control .glyphicon-chevron-left,
    .banner1 .carousel-control .icon-prev {
        right: 0;
        left: inherit;
    }

    .banner1 .carousel-control .glyphicon-chevron-right,
    .banner1 .carousel-control .icon-next {
        left: 0;
        right: inherit;
    }

    .input-indec .quantity-left-minus.btn {
        border-top-left-radius: 0;
        border-top-right-radius: inherit;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: inherit;
        border-left: 0;
        border-right: inherit;
    }

    .input-indec .quantity-right-plus.btn {
        border-top-right-radius: 0;
        border-top-left-radius: inherit;
        border-bottom-right-radius: 0;
        border-bottom-left-radius: inherit;
        border-right: 0;
        border-left: inherit;
    }

    .cat-item a {
        right: 0;
        left: inherit;
    }

    .modal .about:before {
        right: -10px;
        left: inherit;
    }

    .modal .about:after {
        right: -20px;
        left: inherit;
    }

    .Marketplace .item-card7-overlaytext h4 {
        left: 5px;
        right: inherit;
    }

    #thumbcarousel .carousel-control-prev {
        right: 0;
        left: inherit;
    }

    #thumbcarousel .carousel-control-next {
        left: 0;
        right: inherit;
    }

    .ace-responsive-menu {
        margin-left: 1rem;
        margin-right: inherit;
    }

    @media (min-width: 992px) {
        .typewrite-text .select2-container .select2-selection--single {
            border-radius: 0 7px 7px 0 !important;
            border-left: 0 !important;
            border-right: 1px solid $border !important;
        }

        &.dark-theme .typewrite-text .select2-container .select2-selection--single {
            border-left: 0 !important;
            border-right: 1px solid $dark-border !important;
        }

        .typewrite-text .form-control.input-lg {
            border-top-right-radius: 0 !important;
            border-top-left-radius: inherit !important;
            border-bottom-right-radius: 0 !important;
            border-bottom-left-radius: inherit !important;
        }
    }

    @media (max-width: 991px) {

        .banner-1 .search-background .form-control {
            border-left: 1px solid $border !important;
            border-right: inherit !important;
        }

        .banner-1 .search-background .form1 {
            border-right: 1px solid $border !important;
            border-left: inherit !important;
        }

        .banner-1 .search-background .btn-lg {
            left: 0 !important;
            right: inherit !important;
        }

    }

    .banner-1 .item-search-tabs .search-background .form-control {
        border-radius: 0 7px 0 7px;
    }

    .banner-1 .search-background .form-control {
        border-radius: 0 7px 7px 0;
        border-left: 0;
        border-right: 1px solid $border;
    }

    .banner-1 .search-background .form1 {
        border-radius: 0 7px 0 7px;
        border-right: 1px solid $border !important;
        border-left: inherit !important;
    }

    .banner-1 .search-background .btn-xl {
        border-radius: 7px 0 0 7px !important;
    }

    @media (max-width: 991px) {
        .banner-1 .search-background .btn.btn-xl {
            border-radius: 7px !important;
        }
    }

    .banner-1 .search-background .select2-container--default .select2-selection--single {
        border-left: 0 !important;
        border-right: inherit !important;
    }

    .support-list li i {
        margin-left: 0.7rem;
        margin-right: inherit;
    }

    .profile-pic-img span {
        left: -1px;
        right: inherit;
    }

    .payments .tabs-menu1 ul li:first-child a {
        margin-right: 0;
        margin-left: inherit;
    }

    .ads-tabs .tabs-menus ul li {
        margin-left: 1rem;
        margin-right: inherit;
    }

    .ads-tabs .tabs-menus ul li:last-child {
        margin-left: 0;
        margin-right: inherit;
    }

    .slider .owl-nav .owl-prev {
        right: 15px;
        left: inherit;
    }

    .slider .owl-nav .owl-next {
        left: 15px;
        right: inherit;
    }

    .owl-nav .owl-prev {
        right: -25px;
        left: -1.5em;
    }

    .owl-nav .owl-next {
        left: -25px;
        right: inherit;
    }

    @media (max-width: 480px) {
        .owl-nav .owl-prev {
            right: -10px;
            left: inherit;
        }

        .owl-nav .owl-next {
            left: -10px;
            right: inherit;
        }
    }

    .classes-carousel.owl-carousel.owl-drag .owl-item .icon-circle-style {
        border-radius: 0 7px 7px 0;
    }

    .profile-pic .d-md-flex {
        text-align: right;
    }

    @media (max-width: 480px) {

        .ads-tabs .tabs-menus ul li {
            margin-left: 0;
            margin-right: inherit;
        }

        .zoom-container .arrow-ribbon2:before {
            left: -17px;
            right: inherit;
        }
    }

    @media (min-width: 561px) and (max-width: 767px) {

        .wideget-user-tab .tabs-menu1 ul li {
            margin: 0 0 0 6px;
        }

        .navtab-wizard.nav-tabs .nav-link {
            border-right: 0;
            border-left: inherit;
        }
    }

    .owl-carousel-icons5 .owl-nav .owl-prev {
        right: auto;
        left: -24px;
    }

    .owl-carousel-icons5 .owl-nav .owl-next {
        right: auto;
        left: -24px;
    }

    .ecommerce-menu.horizontalMenu>.horizontalMenu-list>li>a .fa {
        margin-left: 9px;
        margin-right: inherit;
    }

    #homeVideo button.btn.btn-default {
        left: 5px;
        right: inherit;
    }

    .gm-ui-hover-effect {
        left: 0 !important;
        right: inherit !important;
    }

    button#ihavecookiesBtn {
        margin-right: 0 !important;
        margin-left: inherit !important;
    }

    #gdpr-cookie-message {
        right: 0;
        left: inherit;
        margin-right: 30px;
        margin-left: inherit;
    }

    button#gdpr-cookie-advanced {
        margin-right: 4px;
        margin-left: inherit;
    }

    @media (max-width: 390px) {
        button#gdpr-cookie-advanced {
            margin-right: 0 !important;
            margin-left: inherit !important;
        }
    }

    @media (max-width: 480px) {
        #gdpr-cookie-message {
            left: 0;
            right: inherit;
        }
    }

    #gdpr-cookie-message input[type=checkbox] {
        margin-left: 5px;
        margin-right: inherit;
    }

    h1.animated-text {
        border-left: 0.15em solid orange;
        border-right: inherit;
    }

    .single-page .wrapper.wrapper2 {
        right: 4%;
        left: inherit;
    }

    .single-page .wrapper label {
        float: right;
    }

    .single-page>.wrapper.move {
        right: 45%;
        left: inherit;
    }

    .location-gps {
        left: 12px;
        right: inherit;
        text-align: left;
    }

    .activity {
        border-right: 1px solid #eaf2f9;
        border-left: inherit;
        margin-right: 16px;
        margin-left: inherit;
    }

    .activity .img-activity {
        right: -18px;
        left: inherit;
    }

    .activity .item-activity {
        margin-right: 40px;
        margin-left: inherit;
    }

    body .modal-open {
        padding-left: 0 !important;
        padding-right: inherit !important;
    }

    /* background animations*/
    .circles {
        right: 0;
        left: inherit;
    }

    .circles li:nth-child(1) {
        right: 25%;
        left: inherit;
    }

    .circles li:nth-child(2) {
        right: 10%;
        left: inherit;
    }

    .circles li:nth-child(3) {
        right: 70%;
        left: inherit;
    }

    .circles li:nth-child(4) {
        right: 40%;
        left: inherit;
    }

    .circles li:nth-child(5) {
        right: 65%;
        left: inherit;
    }

    .circles li:nth-child(6) {
        right: 75%;
        left: inherit;
    }

    .circles li:nth-child(7) {
        right: 35%;
        left: inherit;
    }

    .circles li:nth-child(8) {
        right: 50%;
        left: inherit;
    }

    .circles li:nth-child(9) {
        right: 20%;
        left: inherit;
    }

    .circles li:nth-child(10) {
        right: 85%;
        left: inherit;
    }

    @media (min-width: 991px) {

        .clients-img img:nth-child(1) {
            right: 15px;
            left: inherit;
        }

        .clients-img img:nth-child(2) {
            left: 50px;
            right: inherit;
        }

        .clients-img img:nth-child(3) {
            right: 60px;
            left: inherit;
        }
    }

    .details-tab-content {
        border-radius: 3px 0 0px 0px;
    }

    .product-slider .class-video:before {
        left: 0;
        right: inherit;
    }

    .sectiontitle-design:before {
        right: -30px;
        left: inherit;
    }

    .sectiontitle-design:after {
        left: -31px;
        right: inherit;
    }

    .main-banner-png {
        left: 0;
        right: inherit;
    }

    .item-card2-icons-over-lay {
        border-radius: 0 25px 25px 0;
        left: -100px;
        right: inherit;
    }

    .item-card2-icons-over-lay .item-card2-icons {
        left: auto;
        right: inherit;
    }

    .card:hover .item-card2-icons-over-lay {
        left: -3px;
        right: inherit;
    }

    .video-img:before {
        right: -25px;
        left: inherit;
    }

    .student-img img {
        left: 0;
        right: inherit;
    }

    .testimonial-img:before {
        right: -10px;
        left: inherit;
    }

    .student-feature-icons li {
        float: right;
    }

    .bg-background-1 .select2-lg .select2-container--default .select2-selection--single .select2-selection__rendered {
        padding-right: 20px !important;
        padding-left: inherit !important;
    }

    .map-background-search .select2-lg .select2-container--default .select2-selection--single .select2-selection__rendered {
        padding-right: 20px !important;
        padding-left: inherit !important;
    }

    .works-timeline:before {
        left: -50%;
        right: inherit;
    }

    .about-2 {
        border-bottom-right-radius: 1rem !important;
        border-bottom-left-radius: inherit !important;
        border-top-left-radius: 1rem !important;
        border-top-right-radius: inherit !important;
    }

    .feature-1:before {
        right: 85px;
        left: inherit;
        border-radius: 7px;
        border-top-left-radius: 150px;
    }

    .feature-1 .feature {
        border-top-left-radius: 150px;
        border-top-right-radius: inherit;
    }

    .item2-gl-nav .select2-container--default .select2-selection--single .select2-selection__rendered {
        text-align: right;
    }

    .aboutlink a {
        right: 50%;
        left: inherit;
        margin-right: -110px;
        margin-left: inherit;
    }

    .aboutlink a i {
        margin-right: 105px;
        margin-left: inherit;
    }

    .aboutlink a:after {
        right: 0;
        left: inherit;
    }

    @media (min-width: 992px) and (max-width: 1279px) {
        .feature-1:before {
            right: 51px;
            left: inherit;
        }
    }

    .product-tags-1 a {
        float: right;
        margin-left: 5px;
        margin-right: inherit;
    }


    #commentForm #rootwizard .navtab-wizard li a {
        border-right: 0;
        border-left: inherit;
    }

    .profile-2 .file-browser .input-group-btn .btn {
        border-top-right-radius: 0;
        border-top-left-radius: 7px;
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 7px;
    }

    .my-dash.app-sidebar .mCSB_draggerContainer {
        left: -11px;
        right: inherit;
    }

    .my-dash .slide-menu li ul a {
        padding: 10px 65px 10px 10px;
    }

    .my-dash .app-sidebar-footer a {
        float: right;
        border-left: 1px solid #e8ebf3;
        border-right: inherit;
    }

    .my-dash .slide ul ul a {
        padding: 0px 64px 0px 0px !important;
    }

    .my-dash .sub-slide .sub-angle {
        margin-left: 20px;
        margin-right: inherit;
    }

    .my-dash .slide-item {
        padding: 0px 45px 0px 0px;
    }

    .my-dash .slide-item .icon {
        margin-left: 5px;
        margin-right: inherit;
    }

    .item7-card-text .item-card-badge {
        right: auto;
        left: 10px;
    }

    .faqs .panel-heading1 a.collapsed:before {
        left: 18px;
        right: inherit;
    }

    .faqs .panel-heading1 a:before {
        left: 18px;
        right: inherit;
    }

    .single-page.contact .wrapper.wrapper2 {
        right: 0%;
        left: inherit;
    }

    .latest-timeline-2 .activity1 {
        margin-left: 20px;
        margin-right: inherit;
    }

    .task-1 {
        margin-left: 15px;
        margin-right: inherit;
    }

    @media screen and (max-width: 1279px) {
        .main-banner-png {
            left: 0;
            right: inherit;
        }
    }

    @media screen and (max-width: 991px) {

        #commentForm #rootwizard .tab-pane#fourth .tabs-menu1 ul li a {
            border-left: 0 !important;
            border-right: inherit !important;
        }

        .profile-1 .wideget-user-tab .tabs-menu1 ul li {
            margin: 5px 0 0 6px;
        }
    }

    @media screen and (max-width: 767px) {
        .header-main .top-bar-light .top-bar-end .custom li:last-child a {
            border-left: 1px solid $white-1;
            border-right: inherit;
        }  
    }

    @media (max-width: 480px) {
        .item-list .item-card9-icons2 {
            left: 0;
            right: inherit;
        }

        .item-list1 .item-card9-icons2 {
            left: 0;
            right: inherit;
        }

        .item-list2 .item-card9-icons2 {
            left: 0;
            right: inherit;
        }
    }

    .header-text .breadcrumb-item+.breadcrumb-item {
        padding-right: 0;
        padding-left: inherit;
    }

    .pull-end {
        float: left;
    }

    .pull-start {
        float: right;
    }

    .widget-spec-1 li i {
        margin-left: 10px;
        margin-right: inherit;
    }

    .user-pro-list .avatar-status {
        left: 8px;
        right: inherit;
    }

    .gallery-item .gallery-card .gallery-card-desc:before {
        left: 0;
        right: inherit;
    }

    .classes-carousel-1 .item-card .item-card-desc .item-card-text {
        right: 48%;
        left: inherit;
        transform: translate(50%, -50%);
    }

    .video-icon i {
        padding-right: 3px;
        padding-left: inherit;
    }

    .jq-star-svg {
        padding-right: 0;
        padding-left: inherit;
    }

    .imagecheck-figure:before {
        right: 0.25rem;
        left: inherit;
    }

    .demo-gallery>ul>li {
        float: right;
    }

    .demo-gallery>ul>li a {
        float: right;
    }

    .demo-gallery>ul>li a .demo-gallery-poster>img {
        right: 50%;
        left: inherit;
        margin-right: -10px;
        margin-left: inherit;
    }

    .demo-gallery .justified-gallery>a .demo-gallery-poster>img {
        right: 50%;
        left: inherit;
        margin-right: -10px;
        margin-left: inherit;
    }

    .demo-gallery .video .demo-gallery-poster img {
        margin-right: -24px;
        margin-left: inherit;
    }

    .gallery .content,
    .gallery .mask {
        right: 0;
        left: inherit;
    }

    .label.arrowed {
        margin-right: 9px;
        margin-left: inherit;
    }

    .label.arrowed-in {
        margin-right: 9px;
        margin-left: inherit;
    }

    .label.arrowed-in:before {
        right: -14px;
        left: inherit;
    }

    .label.arrowed:before {
        right: -14px;
        left: inherit;
    }

    .label.arrowed-in-right,
    .label.arrowed-right {
        margin-left: 9px;
        margin-right: inherit;
        border-radius: 2px 0 0 2px;
    }

    .label-primary.arrowed:before {
        border-left-color: $primary;
        border-right-color: transparent;
    }

    .label-info.arrowed:before {
        border-left-color: #04b2e5;
        border-right-color: transparent;
    }

    .label-danger.arrowed:before {
        border-left-color: #f84242;
        border-right-color: transparent;
    }

    .label-success.arrowed:before {
        border-left-color: #21b632;
        border-right-color: transparent;
    }

    .label-warning.arrowed:before {
        border-left-color: #ffa22b;
        border-right-color: transparent;
    }

    .list-unstyled,
    .list-inline {
        padding-right: 0;
        padding-left: initial;
    }

    .list-inline-item:not(:last-child) {
        margin-left: 0.5rem;
        margin-right: inherit;
    }

    .list-group {
        padding-right: 0;
        padding-left: inherit;
    }

    .list-media .media-img {
        float: right;
        margin-left: 20px;
        margin-right: inherit;
    }

    .list-media .info {
        padding-right: 55px;
        padding-left: inherit;
    }

    .list-media .info .text-end {
        left: 0;
        right: inherit;
    }

    .list-inline-dots .list-inline-item+.list-inline-item:before {
        margin-right: -2px;
        margin-left: 3px;
    }

    .list-style-1 {
        padding-right: inherit;
        padding-left: inherit;
    }

    .list-style-1 ul {
        padding-right: 30px;
        padding-left: inherit;
    }

    .list-style2 {
        padding-right: inherit;
        padding-left: inherit;
    }

    .list-style2 ul {
        padding-right: 30px;
        padding-left: inherit;
    }

    .list-style3 {
        padding-right: inherit;
        padding-left: inherit;
    }

    .list-style3 ul {
        padding-right: 30px;
        padding-left: inherit;
    }

    .list-style4 {
        padding-right: inherit;
        padding-left: inherit;
    }

    .list-style4 ul {
        padding-right: 30px;
        padding-left: inherit;
    }

    .list-style5 {
        padding-right: inherit;
        padding-left: inherit;
    }

    .list-style5 ul {
        padding-right: 30px;
        padding-left: inherit;
    }

    .list-style6 {
        padding-right: inherit;
        padding-left: inherit;
    }

    .list-style6 ul {
        padding-right: 30px;
        padding-left: inherit;
    }

    .media-end,
    .media>.pull-end {
        padding-right: 10px;
        padding-left: inherit;
    }

    .media-start,
    .media>.pull-start {
        padding-left: 10px;
        padding-right: inherit;
    }

    .media-start {
        padding-left: 10px;
        padding-right: inherit;
    }

    .media>.pull-start {
        padding-left: 10px;
        padding-right: inherit;
    }

    .navbar-brand {
        margin-left: 1rem;
        margin-right: inherit;
    }

    .navbar-nav {
        padding-right: 0;
        padding-left: inherit;
    }

    .pricingTable .title:after,
    .pricingTable .title:before {
        border-left: 26px solid rgba(233, 30, 99, 0.1);
        border-right: inherit;
        right: -26px;
        left: inherit;
    }

    .pricingTable .title:after {
        border-left: none;
        border-right: 26px solid rgba(233, 30, 99, 0.1);
        right: auto;
        left: -26px;
    }

    .price-value1 span {
        margin-right: 17px;
        margin-left: inherit;
    }

    .pricingTable .title:after {
        border-right: 26px solid rgba(255, 56, 43, 0.1);
        border-left: inherit;
    }

    .br-theme-bars-1to10 .br-widget a {
        float: right;
    }

    .br-theme-bars-1to10 .br-widget .br-current-rating {
        float: right;
    }

    .br-theme-bars-movie .br-widget a {
        float: right;
    }

    .br-theme-fontawesome-stars .br-widget a {
        margin-left: 2px;
        margin-right: inherit
    }

    .br-theme-bootstrap-stars .br-widget a {
        margin-left: 2px;
        margin-right: inherit
    }

    .br-theme-bootstrap-stars .br-widget a {
        margin-left: 2px;
        margin-right: inherit
    }

    .br-theme-bars-square .br-widget a {
        float: right;
    }

    .br-theme-bars-reversed .br-widget a {
        float: right;
    }

    .br-theme-bars-reversed .br-widget .br-current-rating {
        float: right;
    }

    .br-theme-bars-pill .br-widget a:first-child {
        border-top-right-radius: 999px;
        border-top-left-radius: inherit;
        border-bottom-right-radius: 999px;
        border-bottom-left-radius: inherit;
    }

    .br-theme-bars-pill .br-widget a:last-child {
        border-top-left-radius: 999px;
        border-top-right-radius: inherit;
        border-bottom-left-radius: 999px;
        border-bottom-right-radius: inherit;
    }

    @media print {
        .br-theme-bars-pill .br-widget a {
            border: 1px solid $border;
            border-right: none;
        }

        .br-theme-bars-pill .br-widget a.br-active,
        .br-theme-bars-pill .br-widget a.br-selected {
            border: 1px solid black;
            border-right: none;
        }

        .br-theme-bars-pill .br-widget a:first-child {
            border-right: 1px solid black;
            border-left: inherit;
        }
    }

    .br-theme-fontawesome-stars-o .br-widget a {
        margin-left: 2px;
        margin-right: inherit;
    }

    #commentForm #rootwizard .tab-pane#third .file-browser .input-group-btn .btn {
        border-top-right-radius: 0;
        border-top-left-radius: 7px;
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 7px;
    }

    .tab_wrapper.first_tab ul li {
        float: right;
        margin: 0 5px 0 0;
    }

    .timeline:before {
        right: 4px;
        left: inherit;
    }

    .timeline-item {
        padding-right: 2rem;
        padding-left: inherit;
    }

    .timeline-item:first-child:before,
    .timeline-item:last-child:before {
        right: 0.25rem;
        left: inherit;
    }

    .timeline-badge {
        right: 1px;
        left: inherit;
    }

    .footer .social ul li {
        float: right;
    }

    .footer-main .social li {
        float: right;
        margin-left: 15px;
        margin-right: inherit;
    }

    .social li {
        margin-left: 15px;
        margin-right: inherit;
        float: left;
    }

    .social-icons li {
        margin-left: 15px;
        margin-right: inherit;
    }

    .footer-main .payments li {
        float: right;
        margin-left: 15px;
        margin-right: inherit;
    }

    .footer-links a:before {
        right: -2px;
        left: inherit;
    }

    .footer-payments {
        padding-right: 0;
        padding-left: inherit;
    }

    @media (min-width: 768px) {
        .footer.footer-style {
            margin-right: 0px !important;
            margin-left: inherit !important;
        }
    }

    .header-brand {
        margin-left: 1rem;
        margin-right: inherit;
    }

    .app-header .header-brand {
        margin-left: 0;
        margin-right: inherit;
    }

    .app-header .header-brand .header-brand-img {
        margin-left: 0;
        margin-right: inherit;
    }

    .header-brand-img {
        margin-left: 0.5rem;
        margin-right: inherit;
    }

    .header-btn.has-new:before {
        left: 4px;
        right: inherit;
    }

    .header-toggler-icon {
        right: 50%;
        left: inherit;
        margin: -2px -0.5rem 0 0;
    }

    .header .nav-item .badge,
    .header .nav-link .badge {
        left: -1px;
        right: inherit;
    }

    .header-main .social-icons {
        float: left;
    }

    .header-main .social-icons li {
        margin-left: 35px;
        margin-right: inherit;
    }

    .header-search .header-icons .header-icons-link li a {
        margin-left: 5px;
        margin-right: inherit;
    }

    .header-search .header-icons .header-icons-link1 .main-badge1 {
        left: -5px;
        right: inherit;
    }

    .top-bar .top-bar-end {
        float: left;
    }

    .top-bar .top-bar-start .socials li a {
        border-right: 1px solid $white-1;
        border-left: inherit;
    }

    .top-bar .top-bar-start .socials li:last-child a {
        border-left: 1px solid $white-1;
        border-right: inherit;
    }

    .header-search .header-inputs .input-group-text.searchicon {
        left: 25px;
        right: inherit;
    }

    .header-search .header-search-logo {
        margin-left: 1rem;
        margin-right: inherit;
    }

    .header-main .top-bar .contact li {
        border-left: 1px solid $white-1;
        border-right: inherit;
    }

    .header-main .top-bar-end .custom li {
        margin-left: 0;
        margin-right: inherit;
    }

    .header-main .top-bar-end .custom li:last-child {
        margin-left: 0;
        margin-right: inherit;
        border-left: 1px solid $white-1;
        border-right: inherit;
    }

    .header-main .top-bar-end .custom li a {
        border-right: 1px solid $white-1;
        border-left: 0;
    }

    .header-main .post-btn {
        float: left;
        margin-right: auto;
        margin-left: inherit;
    }

    .top-bar.top-bar-light .top-bar-end li:last-child a {
        border-left: 1px solid $white-1;
        border-right: inherit;
    }

    .desktoplogo {
        float: right;
    }

    .horizontalMenu {
        float: left;
    }

    .horizontalMenu>.horizontalMenu-list {
        text-align: right;
    }

    .horizontalMenu>.horizontalMenu-list>li {
        float: right;
    }

    .horizontalMenu>.horizontalMenu-list>li:last-child>a {
        border-left: 0px;
        border-right: inherit;
    }

    .horizontalMenu>.horizontalMenu-list>li>a .fa {
        margin-left: 7px;
        margin-right: inherit;
    }

    .horizontalMenu>.horizontalMenu-list>li>a .arrow:after {
        float: left;
        left: 11px;
        right: inherit;
        margin: 0 14px 0 0;
        text-align: left;
    }

    .horizontalMenu>.horizontalMenu-list>li>ul.sub-menu>li>a {
        text-align: right;
        border-left: 0px solid;
        border-right: inherit;
    }

    .horizontalMenu>.horizontalMenu-list>li>ul.sub-menu>li>a>.fa {
        margin-left: 9px;
        margin-right: inherit;
    }

    .horizontalMenu>.horizontalMenu-list>li>ul.sub-menu>li>ul.sub-menu {
        right: 100%;
        left: inherit;
    }

    .horizontalMenu>.horizontalMenu-list>li>ul.sub-menu>li>ul.sub-menu>li>a {
        text-align: right;
        border-left: 0px solid;
        border-right: inherit;
    }

    .horizontalMenu>.horizontalMenu-list>li>ul.sub-menu>li>ul.sub-menu>li>a>.fa {
        margin-left: 9px;
        margin-right: inherit;
    }

    .horizontalMenu>.horizontalMenu-list>li>ul.sub-menu>li>ul.sub-menu>li>ul.sub-menu {
        right: 100%;
        left: inherit;
    }

    .horizontalMenu>.horizontalMenu-list>li>ul.sub-menu>li>ul.sub-menu>li>ul.sub-menu>li>a {
        text-align: right;
        border-left: 0px solid;
        border-right: inherit;
    }

    .horizontalMenu>.horizontalMenu-list>li>ul.sub-menu>li>ul.sub-menu>li>ul.sub-menu>li>a>.fa {
        margin-left: 9px;
        margin-right: inherit;
    }

    .horizontalMenu>.horizontalMenu-list>li>.horizontal-megamenu {
        right: 0px;
        left: inherit;
        text-align: right;
    }

    .horizontalMenu>.horizontalMenu-list>li>.horizontal-megamenu .title {
        padding: 5px 0 5px 5px;
        text-align: right;
    }

    .horizontalMenu>.horizontalMenu-list>li>.horizontal-megamenu .link-list li {
        text-align: right;
    }

    .horizontalMenu>.horizontalMenu-list>li>.horizontal-megamenu .link-list li a {
        text-align: right;
        border-left: 0 none;
        border-right: inherit;
    }

    .horizontalMenu>.horizontalMenu-list>li>.horizontal-megamenu li .fa {
        margin-left: 5px;
        margin-right: inherit;
    }

    .horizontalMenu>.horizontalMenu-list>li>.horizontal-megamenu li .fa {
        margin-left: 5px;
        margin-right: inherit;
    }

    .horizontalMenu>.horizontalMenu-list>li>.horizontal-megamenu .menu_form input[type=submit] {
        float: left;
        margin-left: 15px;
        margin-right: inherit;
    }

    .horizontalMenu>.horizontalMenu-list>li>.horizontal-megamenu .menu_form input[type=button] {
        float: left;
    }


    @media (min-width: 1024px) {
        .horizontalMenu>.horizontalMenu-list>li>.horizontal-megamenu .link-list {
            border-right: 1px solid #e8ebf3;
            border-left: inherit;
        }
    }

    @media only screen and (max-width: 991px) {

        .horizontalMenu {
            right: 0;
            left: inherit;
        }

        .horizontalMenu>.horizontalMenu-list {
            margin-right: -240px;
            margin-left: inherit;
        }

        .horizontalMenu>.horizontalMenu-list>li {
            border-left: none;
            border-right: inherit;
            clear: left;
        }

        .horizontalMenu>.horizontalMenu-list>li>a {
            padding: 8px 17px 8px 32px;
            text-align: right;
            border-left: solid 0px;
            border-right: inherit;
        }

        .horizontalMenu>.horizontalMenu-list>li>ul.sub-menu>li>a {
            padding: 10px 16px 10px 0;
        }

        // .horizontalMenu>.horizontalMenu-list>li>ul.sub-menu>li span+a {
        //     padding-left: 30px;
        //     padding-right: inherit;
        // }

        .horizontalMenu>.horizontalMenu-list>li>ul.sub-menu>li>ul.sub-menu {
            right: 100%;
            left: inherit;
        }

        .horizontalMenu>.horizontalMenu-list>li>ul.sub-menu>li>ul.sub-menu>li>a {
            padding: 10px 26px 10px 0px;
        }

        // .horizontalMenu>.horizontalMenu-list>li>ul.sub-menu>li>ul.sub-menu>li span+a {
        //     padding-left: 30px;
        //     padding-right: inherit;
        // }

        .horizontalMenu>.horizontalMenu-list>li>ul.sub-menu>li>ul.sub-menu>li>ul.sub-menu {
            right: 100%;
            left: inherit;
        }

        .horizontalMenu>.horizontalMenu-list>li>ul.sub-menu>li>ul.sub-menu>li>ul.sub-menu>li>a {
            padding: 10px 34px 10px 0;
        }

        .horizontalMenu>.horizontalMenu-list>li>ul.sub-menu>li>ul.sub-menu>li>ul.sub-menu>li span+a {
            padding-left: 30px;
            padding-right: inherit;
        }

        .horizontalMenu>.horizontalMenu-list>li>.horizontal-megamenu .title {
            padding: 10px 0 10px 8px;
        }

        .horizontal-header {
            right: 0;
            left: inherit;
        }

        .callusbtn {
            left: 10px;
            right: inherit;
        }

        .animated-arrow {
            right: 0;
            left: inherit;
            padding: 18px 9px 18px 30px;
            margin: 8px 15px 0 0;
        }

        .horizontal-overlapbg {
            left: 0;
            right: initial;
        }

        &.active .horizontalMenucontainer {
            margin-right: 240px;
            margin-left: inherit;
        }

        &.active .horizontalMenu>.horizontalMenu-list {
            margin-right: 0;
            margin-left: inherit;
        }

        .horizontalMenu>.horizontalMenu-list>li>.horizontalMenu-click {
            left: 0;
            right: inherit;
        }

        .horizontalMenu>.horizontalMenu-list>li>.horizontalMenu-click>i {
            margin-left: 10px;
            margin-right: inherit;
            float: left;
        }

        .horizontalMenu>.horizontalMenu-list>li>ul.sub-menu>li .horizontalMenu-click02 {
            left: 0;
            right: inherit;
        }

        .horizontalMenu>.horizontalMenu-list>li>ul.sub-menu>li .horizontalMenu-click02>i {
            float: left;
        }
    }

    .horizontalMenu-list .sub-menu:before,
    .horizontalMenu-list .halfdiv:before {
        right: 12px;
        left: inherit;
    }

    .horizontalMenu-list .sub-menu .sub-menu:before,
    .horizontalMenu-list .sub-menu .sub-menu:after {
        right: -12px;
        left: inherit;
        transform: rotate(90deg);
    }

    .horizontalMenu-list .sub-menu:after {
        right: 12px;
        left: inherit;
    }

    .horizontalMenu-list .halfdiv:after {
        right: 12px;
        left: inherit;
    }

    .horizontalMenu-list .sub-menu:before,
    .horizontalMenu-list .sub-menu:after {
        right: 12px;
        left: auto;
    }

    .horizontalMenu-list .halfdiv:before,
    .horizontalMenu-list .halfdiv:after {
        right: 12px;
        left: auto;
    }

    .icons-list {
        border-right: 1px solid $border;
        border-left: inherit;
    }

    .icons-list-item {
        border-left: 1px solid $border;
        border-right: 0;
    }

    .item-card .cardprice {
        left: 15px;
        right: inherit;
    }

    .item-card .item-card-desc:before {
        left: 0;
        right: inherit;
    }

    .item-card-desc a {
        right: 0;
        left: inherit;
    }

    .item-card .item-card-desc .item-card-text.item-card-text-footer {
        text-align: right;
    }

    .item-card .item-card-btn {
        right: 37.7%;
        left: inherit;
    }

    .item-card .item-card-btn a:after,
    .item-card .item-card-btn a:before {
        right: 50%;
        left: inherit;
    }

    .item-card2-icons {
        left: 15px;
        right: inherit;
    }

    .item-list .list-group-item i {
        margin-left: 5px;
        margin-right: inherit;
    }

    .item-card4-img a {
        right: 30px;
        left: inherit;
    }

    .item-card5-img a {
        left: 12px;
        right: inherit;
    }

    .item-search-tabs .tab-content {
        margin: 1px 0 0 5px;
    }

    .item-search-tabs .form-control.border {
        border-top-left-radius: 0;
        border-top-right-radius: inherit;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: inherit;
    }

    .item-search-tabs a.btn {
        border-top-right-radius: 0;
        border-top-left-radius: 7px;
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 7px;
        left: -2px;
        right: inherit;
    }

    .item-all-card:before {
        right: 0;
        left: inherit;
    }

    .item-all-cat .item-all-card a.absolute-link {
        right: 0;
        left: inherit;
    }

    .item-card7-imgs .bdir {
        right: 12px;
        left: inherit;
    }

    .item-card7-overlaytext a {
        right: 12px;
        left: inherit;
    }

    .item-card7-overlaytext h4 {
        left: 12px;
        right: inherit;
    }

    .item-card7-imgs:before {
        left: 0;
        right: inherit;
    }

    .item-card8-imgs:before {
        left: 0;
        right: inherit;
    }

    .item-card8-overlaytext h6 {
        right: 21px;
        left: inherit;
    }

    .item-card2-img:before {
        left: 0;
        right: inherit;
    }

    .item-card7-imgs a {
        right: 0;
        left: inherit;
    }

    .item-card9-img .item-card9-imgs a {
        right: 0;
        left: inherit;
    }

    .item-card2-img a {
        right: 0;
        left: inherit;
    }

    .item-card7-desc ul li {
        margin-left: 1rem;
        margin-right: inherit;
    }

    .item-card7-desc ul li:last-child {
        margin-left: 0;
        margin-right: inherit;
    }

    .item1-card .item1-card-info .item1-card-icons li {
        margin-left: 15px;
        margin-right: inherit;
    }

    .item-video i {
        margin-right: 5px;
        margin-left: inherit;
    }

    .item2-gl-nav .item2-gl-menu {
        margin-left: 10px;
        margin-right: inherit;
    }

    .item-card9-icons {
        left: 15px;
        right: inherit;
    }

    .item-card9-imgs a {
        right: 0;
        left: inherit;
    }

    .item-card9-desc ul li {
        float: right;
    }

    .item-card9-desc .icon-card li {
        float: right;
    }

    .item3-medias .item3-lists li {
        margin-left: 5px;
        margin-right: inherit;
    }

    .item3-medias .item3-lists li a {
        padding: 5px 0 5px 4px;
    }

    .item3-medias .item3-lists li i {
        margin-left: 5px;
        margin-right: inherit;
    }

    .item-card2-desc ul li {
        float: right;
        margin-left: 0;
        margin-right: inherit;
    }

    .item-card2-desc ul li:last-child {
        margin-left: 0;
        margin-right: inherit;
    }

    .item7-card-desc a:first-child {
        margin-left: 1rem;
        margin-right: inherit;
    }

    .item7-card-img a {
        right: 0;
        left: inherit;
    }

    .item-cards7-ic li {
        float: right;
        margin-left: 0 !important;
        margin-right: inherit !important;
    }

    .item7-card-text span {
        right: 10px;
        left: inherit;
    }

    @media (max-width: 767px) {
        .item-user .profile-pic .d-md-flex .ml-4 {
            margin-right: 0 !important;
            margin-left: inherit !important;
        }
    }

    .item-card7-img:before {
        left: 0;
        right: inherit;
    }

    .item-card7-img h4 {
        float: left;
    }

    .item-overly-trans .rating-stars {
        right: 12px;
        left: inherit;
    }

    .item-overly-trans a {
        right: 15px;
        left: inherit;
    }

    .item-tag {
        left: 15px;
        right: inherit;
    }

    .item-tag h4 {
        float: left;
    }

    .item-card7-icons {
        right: 15px;
        left: inherit;
    }

    .item-card7-icons a {
        margin-left: -2px;
        margin-right: inherit;
    }

    .item-card7-icons a:first-child {
        border-radius: 0 8px 8px 0;
    }

    .item-card7-icons a:last-child {
        border-radius: 8px 0 0 8px;
    }

    .item-overly-trans .rating-stars {
        right: 15px;
        left: inherit;
    }

    .item-tags {
        right: 10px;
        left: inherit;
    }

    .item-tags .tag-option {
        float: right;
    }

    .item-all-cat .item-all-card a.absolute-link {
        right: 0;
        left: inherit;
    }

    .item-card9-icons2 {
        left: 15px;
        right: inherit;
    }

    .item-overly-trans2 a {
        left: 15px;
        right: inherit;
    }

    .item-card-text-bottom {
        left: 0;
        right: inherit;
        border-radius: 0 7px 0 7px;
        margin-left: 24px;
        margin-right: inherit;
    }

    .item-card .item-card-btn.data-1 {
        right: 50%;
        left: inherit;
        transform: translate(50%, -50%);
    }

    .double-bounce1 {
        right: 0;
        left: inherit;
    }

    .double-bounce2 {
        right: 0;
        left: inherit;
    }

    .cube1 {
        right: 0;
        left: inherit;
    }

    .cube2 {
        right: 0;
        left: inherit;
    }

    #sidebar li a[aria-expanded=true].active:before {
        right: 0;
        left: inherit;
    }


    &.dark-theme hr.message-inner-separator {
        background-image: -webkit-linear-gradient(right, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.15), rgba(0, 0, 0, 0));
        background-image: -moz-linear-gradient(right, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.15), rgba(0, 0, 0, 0));
        background-image: -ms-linear-gradient(right, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.15), rgba(0, 0, 0, 0));
        background-image: -o-linear-gradient(right, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.15), rgba(0, 0, 0, 0));
    }

    &.dark-theme .btn-loading:after {
        border: 2px solid #fff;
        border-left-color: transparent !important;
    }

    &.dark-theme .btn-animation .btn-loaders:after {
        border: 2px solid #fff;
        border-left-color: transparent !important;
    }

    &.dark-theme .card-pay .tabs-menu li a {
        border-left: 1px solid $dark-border;
        border-right: inherit;
    }

    &.dark-theme .richText .richText-toolbar ul li a {
        border-left: 1px solid $dark-border;
        border-right: inherit;
    }

    &.dark-theme .navtab-wizard.nav-tabs .nav-link {
        border-right: 1px solid $dark-border;
        border-left: inherit;
    }

    &.dark-theme .bs-popover-auto[x-placement^=right] .arrow::before,
    &.dark-theme .bs-popover-right .arrow::before {
        border-left-color: $dark-border;
        border-right-color: inherit;
    }

    &.dark-theme .bs-popover-auto[x-placement^=right] .arrow::after,
    &.dark-theme .bs-popover-right .arrow::after {
        border-left-color: #fff;
        border-right-color: inherit;
    }

    &.dark-theme .bs-popover-auto[x-placement^=left] .arrow::before,
    &.dark-theme .bs-popover-left .arrow::before {
        border-right-color: $dark-border;
        border-left-color: inherit;
    }

    &.dark-theme .bs-popover-auto[x-placement^=left] .arrow::after,
    &.dark-theme .bs-popover-left .arrow::after {
        border-right-color: #fff;
        border-left-color: inherit;
    }

    &.dark-theme .bs-popover-end>.popover-arrow::after,
    &.dark-theme .bs-popover-auto[data-popper-placement^=right]>.popover-arrow::after {
        border-left-color: $dark-theme;
        border-right-color: $white-1;
    }

    &.dark-theme .bs-popover-start>.popover-arrow::after,
    &.dark-theme .bs-popover-auto[data-popper-placement^=left]>.popover-arrow::after {
        border-right-color: $dark-theme;
        border-left-color: $white-1;
    }

    &.dark-theme .other:before {
        border: 5px solid $dark-border;
        border-right-color: transparent;
    }

    &.dark-theme .self .avatar:after {
        border: 5px solid $dark-border;
        border-left-color: transparent;
    }

    &.dark-theme blockquote {
        border-right: 3px solid rgba(227, 237, 252, 0.5);
        border-left: inherit;
    }

    &.dark-theme .blockquote-reverse {
        border-left: 3px solid rgba(227, 237, 252, 0.5);
        border-right: inherit;
    }

    &.dark-theme blockquote.float-right {
        border-left: 3px solid rgba(227, 237, 252, 0.5);
        border-right: inherit;
    }

    &.dark-theme .aside {
        border-right: 1px solid $dark-border;
        border-left: inherit;
    }

    &.dark-theme #messages-main .ms-menu {
        border-left: 1px solid $dark-border;
        border-right: inherit;
    }

    &.dark-theme .message-feed.right .mf-content:before {
        border: 10px solid transparent;
        border-right-color: $dark-border;
    }

    @media (max-width: 991px) {
        &.dark-theme .banner-1 .search-background .form1 {
            border-right: 1px solid $dark-border !important;
            border-left: inherit !important;
        }
    }

    &.dark-theme .banner-1 .search-background .form1 {
        border-right: 1px solid $dark-border !important;
        border-left: inherit !important;
    }

    &.dark-theme .activity {
        border-right: 1px solid $dark-border;
        border-left: inherit;
    }

    &.dark-theme .my-dash .app-sidebar-footer a {
        border-left: 1px solid $dark-border;
        border-right: inherit;
    }

    @media screen and (max-width: 767px) {
        &.dark-theme .header-main .top-bar-light .top-bar-end .custom li:last-child a {
            border-left: 0px solid $white-1;
            border-right: 1px solid $white-1;
        }
    }

    &.dark-theme .pricingTable .title:after,
    &.dark-theme .pricingTable .title:before {
        border-left: 26px solid rgba(233, 30, 99, 0.1);
        border-right: inherit;
    }

    &.dark-theme .pricingTable .title:after {
        border-right: 26px solid rgba(233, 30, 99, 0.1);
        border-left: inherit;
    }

    @media print {
        &.dark-theme .br-theme-bars-pill .br-widget a:first-child {
            border-right: 1px solid black;
            border-left: inherit;
        }
    }

    &.dark-theme .tab_wrapper.right_side .content_wrapper {
        border: 1px solid $dark-border;
        border-left: 0;
    }

    &.dark-theme .top-bar .top-bar-start .socials li a {
        border-right: 1px solid $white-1;
        border-left: inherit;
    }

    &.dark-theme .top-bar .top-bar-start .socials li:last-child a {
        border-left: 1px solid $white-1;
        border-right: 1px solid $white-1;
    }

    &.dark-theme .header-main .top-bar .contact li {
        border-left: 1px solid $white-1;
        border-right: inherit;
    }

    &.dark-theme .header-main .top-bar-end .custom li:last-child {
        border-left: 1px solid $white-1;
        border-right: inherit;
    }

    &.dark-theme .header-main .top-bar-end .custom li a {
        border-right: 1px solid $white-1;
        border-left: 1px solid $white-1;
    }

    &.dark-theme .top-bar.top-bar-light .top-bar-end li:last-child a {
        border-left: 0px solid $white-1;
        border-right: inherit;
    }

    &.dark-theme .icons-list {
        border-right: 1px solid $dark-border;
        border-left: inherit;
    }

    &.dark-theme .icons-list-item {
        border-left: 1px solid $dark-border;
        border-right: 0;
    }

    &.dark-theme .selectize-dropdown.plugin-optgroup_columns .optgroup {
        border-left: 1px solid #f2f2f2;
        border-right: inherit;
    }

    &.dark-theme .selectize-control.plugin-remove_button [data-value] .remove {
        border-right: 1px solid #d0d0d0;
        border-left: inherit;
    }

    &.dark-theme .selectize-control.plugin-remove_button [data-value].active .remove {
        border-right-color: #cacaca;
        border-left-color: inherit;
    }

    &.dark-theme .selectize-control.plugin-remove_button .disabled [data-value] .remove {
        border-right-color: #fff;
        border-left-color: inherit;
    }

    &.dark-theme .cal1 .clndr .clndr-table tr .day {
        border-right: 1px solid $dark-border;
        border-left: inherit;
    }

    &.dark-theme .cal1 .clndr .clndr-table tr .day:last-child {
        border-left: 1px solid $dark-border;
        border-right: inherit;
    }

    &.dark-theme .chat-line-friend .chat-message:after {
        border-left: 5px solid $dark-border;
        border-right: inherit;
    }

    &.dark-theme .user-wideget .border-end {
        border-left: 1px solid $dark-border;
        border-right: inherit;
    }

    @media (min-width: 576px) {
        &.dark-theme .wizard.vertical>.actions {
            border-right: 1px solid $dark-border;
            border-left: 0;
        }

        &.dark-theme .wizard.vertical>.content {
            border-right: 1px solid $dark-border;
            border-left: 0;
        }
    }

    &.dark-theme .border-end {
        border-left: 1px solid $dark-border !important;
        border-right: initial !important;
    }

    &.dark-theme .border-start {
        border-right: 1px solid $dark-border !important;
        border-left: initial !important;
    }

    @media (max-width: 991px) {
        &.dark-theme .banner-1 .search-background .form-control {
            border-left: 1px solid $dark-border !important;
            border-right: 1px solid $dark-border !important;
        }
    }

    &.dark-theme .form-inline .form-control:focus,
    &.dark-theme .form-inline .form-control:hover {
        border: 1px solid $dark-border !important;
        border-left: 0 !important;
    }

    &.dark-theme .header-grid .drop-icon-wrap .drop-icon-item {
        border: 1px solid $dark-border;
        border-right: 0;
        border-top: 0;
    } 

    &.dark-theme .sp-picker-container {
        border-right: 1px solid $dark-border;
        border-left: inherit;
    }

    &.dark-theme .richText .richText-editor {
        border-right: 2px solid $dark-border;
        border-left: inherit;
    }

    &.dark-theme .richText .richText-editor:focus {
        border-right: 2px solid $dark-border;
        border-left: inherit;
    }

    &.dark-theme .richText .richText-undo,
    &.dark-theme .richText .richText-redo {
        border-left: $dark-border solid 1px;
        border-right: inherit;
    }

    &.dark-theme .richText .richText-list.list-rightclick {
        border-left: $dark-border solid 1px;
        border-right: inherit;
    }

    @media (min-width: 1024px) {
        &.dark-theme .horizontalMenu>.horizontalMenu-list>li>.horizontal-megamenu .link-list:first-child {
            border-right: 0 !important;
            border-left: inherit !important;
        }
        &.dark-theme .horizontalMenu>.horizontalMenu-list>li>.horizontal-megamenu .link-list {
            border-right: 1px solid $dark-border;
            border-left: inherit;
        }
    }

    &.dark-theme .banner-1 .search-background .form-control {
        border-left: 0;
        border-right: 1px solid $dark-border;
    }

    &.dark-theme .nav-search .form-control.header-search {
        border: 1px solid $dark-border !important;
        border-left: 0 !important;
    }

    &.dark-theme .form-inline .btn {
        border-right: 0 !important;
        border-left: 1px solid $dark-border !important;
    }

    .checkbox span {
        padding-right: 15px;
        padding-left: inherit;
    }

    .checkbox span:empty {
        float: right;
    }

    .checkbox span:before {
        right: 0;
        left: inherit;
    }

    .checkbox span:after {
        right: 0;
        left: inherit;
    }

    .checkbox input[type=checkbox] {
        margin: 0 0 0 5px;
    }

    .custom-control {
        padding-right: 1.5rem;
        padding-left: inherit;
    }

    .custom-control-inline {
        margin-left: 1rem;
        margin-right: inherit;
    }

    .custom-control-label::before {
        right: 0;
        left: inherit;
    }

    .custom-control-label::after {
        right: 0;
        left: inherit;
    }

    .form-range {
        padding-right: 0;
        padding-left: inherit;
    }

    .form-range::-ms-fill-upper {
        margin-left: 15px;
        margin-right: inherit;
    }

    .form-switch-indicator:before {
        right: 1px;
        left: inherit;
        transition: 0.3s right;
    }

    .form-switch-input:checked~.form-switch-indicator:before {
        right: calc(1rem + 1px);
        left: inherit;
    }

    .form-switch-description {
        margin-right: 0.5rem;
        margin-left: inherit;
    }

    .form-switch {
        padding-right: 0;
        padding-left: inherit;
    }

    .ui-datepicker .ui-datepicker-header .ui-datepicker-next:before {
        left: 5px;
        right: inherit;
    }

    .ui-datepicker .ui-datepicker-header .ui-datepicker-prev:before {
        right: 5px;
        left: inherit;
    }

    .ui-datepicker .ui-datepicker-calendar td {
        text-align: left;
    }

    .ui-datepicker .ui-datepicker-calendar td:last-child {
        border-left: 0;
        border-right: inherit;
    }

    .ui-datepicker-multi .ui-datepicker-group {
        float: right;
    }

    .ui-datepicker-multi .ui-datepicker-group .ui-datepicker-prev::before {
        right: 10px;
        left: inherit;
    }

    .ui-datepicker-multi .ui-datepicker-group .ui-datepicker-next::before {
        left: 10px;
        right: inherit;
    }

    .ui-datepicker-multi .ui-datepicker-group-last {
        padding-left: 0;
        padding-right: inherit;
    }

    .fileinput-button {
        float: right;
        margin-left: 4px;
        margin-right: inherit;
    }

    .fileinput-button input {
        direction: rtl;
        left: 0;
        right: inherit;
    }

    * html .fileinput-button {
        margin: 1px 0 0 -3px;
    }

    .jvectormap-goback,
    .jvectormap-zoomin,
    .jvectormap-zoomout {
        right: 10px;
        left: inherit;
    }

    .jvectormap-legend-cnt-h {
        left: 0;
        right: inherit;
    }

    .jvectormap-legend-cnt-v {
        left: 0;
        right: inherit;
    }

    .jvectormap-legend-cnt-h .jvectormap-legend {
        float: right;
        margin: 0 0 10px 10px;
    }

    .jvectormap-legend-cnt-h .jvectormap-legend .jvectormap-legend-tick {
        float: right;
    }

    .jvectormap-legend-cnt-v .jvectormap-legend {
        margin: 10px 0 0 10px;
    }

    .jvectormap-legend-cnt-v .jvectormap-legend-tick-text {
        padding-right: 3px;
        padding-left: inherit;
    }

    .range output {
        border-bottom-right-radius: 0;
        border-bottom-left-radius: inherit;
        border-top-right-radius: 0;
        border-top-left-radius: inherit;
    }

    .select-country .select2-container .select2-selection--single .select2-selection__rendered {
        padding-right: 3px;
        padding-left: inherit;
    }

    .select-country .select2-container .select2-selection--single .select2-selection__rendered {
        padding-right: 3px;
        padding-left: inherit;
    }

    .selectgroup-item+.selectgroup-item {
        padding-right: -1px;
        padding-left: inherit;
    }

    .selectgroup-item:not(:first-child) .selectgroup-button {
        border-top-right-radius: 0;
        border-top-left-radius: inherit;
        border-bottom-right-radius: 0;
        border-bottom-left-radius: inherit;
    }

    .selectgroup-item:not(:last-child) .selectgroup-button {
        border-top-left-radius: 0;
        border-top-right-radius: inherit;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: inherit;
    }

    .selectgroup-input {
        right: 0;
        left: inherit;
    }

    .selectgroup-pills .selectgroup-item {
        margin-left: 0.5rem;
        margin-right: inherit;
    }

    .selectize-dropdown-header-close {
        left: 8px;
        right: inherit;
    }

    .selectize-dropdown.plugin-optgroup_columns .optgroup {
        border-left: 1px solid #f2f2f2;
        border-right: inherit;
        float: right;
    }

    .selectize-dropdown.plugin-optgroup_columns .optgroup:last-child {
        border-left: 0 none;
        border-right: inherit;
    }

    .selectize-control.plugin-remove_button [data-value] {
        padding-left: 24px !important;
        padding-right: inherit !important;
    }

    .selectize-control.plugin-remove_button [data-value] .remove {
        left: 0;
        right: inherit;
        border-right: 1px solid #d0d0d0;
        border-left: inherit;
        border-radius: 2px 0 0 2px;
    }

    .selectize-control.plugin-remove_button [data-value].active .remove {
        border-right-color: #cacaca;
        border-left-color: inherit;
    }

    .selectize-control.plugin-remove_button .disabled [data-value] .remove {
        border-right-color: #fff;
        border-left-color: inherit;
    }

    .selectize-control.plugin-remove_button .remove-single {
        left: 28px;
        right: inherit;
    }

    .selectize-control.multi .selectize-input.has-items {
        padding: 7px 7px 4px 0.75rem;
    }

    .selectize-control.multi .selectize-input>div {
        margin: 0 0 3px 3px;
    }

    .selectize-input>input {
        margin: 0 0 0 2px !important;
    }

    .selectize-input::after {
        clear: right;
    }

    .selectize-control.single .selectize-input:after {
        left: 12px;
        right: inherit;
    }

    .selectize-dropdown .image,
    .selectize-input .image {
        margin: -1px -4px -1px 0.5rem;
        float: right;
    }

    .selectize-input .image {
        margin: -3px -5px -3px 0.75rem;
    }

    .admin-navbar .nav-item.active+.nav-item .nav-link {
        border-right-width: 0;
        border-left-width: inherit;
    }

    .admin-navbar .nav-item:last-child .nav-link {
        border-left-width: 1px;
        border-right-width: inherit;
    }

    .admin-navbar .nav-item.with-sub>a::after {
        margin-right: 4px;
        margin-left: inherit;
    }

    .admin-navbar .nav-link {
        border-left-width: 0;
        border-right-width: inherit;
    }

    .admin-navbar .nav-link i {
        margin-left: 8px;
        margin-right: inherit;
    }

    .admin-navbar .nav-link .square-8 {
        margin-right: 3px;
        margin-left: inherit;
    }

    .admin-navbar .mega-dropdown .sub-item .section-label {
        text-align: right;
    }

    .admin-navbar .sub-item {
        right: 0;
        left: inherit;
    }

    .admin-navbar .sub-item ul {
        text-align: right;
    }

    .admin-navbar .sub-item .sub-with-sub::after {
        left: 20px;
        right: inherit;
    }

    .admin-navbar .sub-item .sub-with-sub ul {
        padding-right: 15px;
        padding-left: inherit;
    }

    @media (min-width: 992px) {
        .admin-navbar .nav-item.with-sub>a::after {
            margin-right: 8px;
            margin-left: inherit;
        }
    }

    @media (min-width: 992px) {
        .admin-navbar .nav-link .square-8 {
            margin-right: 5px;
            margin-left: inherit;
        }
    }

    @media (max-width: 992px) {
        .admin-navbar .nav-link {
            text-align: right;
        }

        .admin-navbar .sub-item .sub-with-sub ul li a {
            padding-right: 20px;
            padding-left: inherit;
        }
    }

    @media (min-width: 992px) {
        .admin-navbar .sub-item .sub-with-sub ul {
            padding-right: 0;
            padding: 10px 0;
            left: -200px;
            right: inherit;
        }
    }

    .cal1 .clndr .clndr-table .header-days .header-day {
        border-right: 1px solid rgba(5, 117, 230, 0.1);
        border-left: inherit;
    }

    .cal1 .clndr .clndr-table tr .adjacent-month,
    .cal1 .clndr .clndr-table tr .empty,
    .cal1 .clndr .clndr-table tr .my-adjacent-month,
    .cal1 .clndr .clndr-table tr .my-empty {
        border-right: 1px solid #79c2b2;
        border-left: inherit;
    }

    .cal1 .clndr .clndr-table tr .day {
        border-right: 1px solid $border;
        border-left: inherit;
    }

    .cal1 .clndr .clndr-table tr .day:last-child {
        border-left: 1px solid $border;
        border-radius: inherit;
    }

    .map-content-width .mCSB_inside>.mCSB_container {
        margin-left: 0;
        margin-right: inherit;
    }

    .map-content-width .mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
        float: left;
    }

    .chat-message {
        text-align: right;
        margin: 0 2.5rem 0 0.5rem;
    }

    .chat-message:after {
        left: -5px;
        right: inherit;
        border-right: 6px solid #096cf7;
        border-left: inherit;
    }

    .chat-line-friend .chat-message {
        margin-right: 0.5rem;
        margin-left: 2.5rem;
    }

    .chat-line-friend .chat-message:after {
        left: auto;
        right: -5px;
        border-right: 5px solid $border;
    }

    #chat-message {
        left: 20px;
        right: inherit;
    }

    .mail-box .sm-side {
        border-radius:  0 4px 4px 0;
    }

    .mail-box .lg-side {
        border-radius: 4px 0 0 4px;
    }

    .mail-box .sm-side .user-head {
        border-radius: 0 4px 0 0;
    }

    .user-head .inbox-avatar {
        float: right;
    }

    .user-head .user-name {
        margin: 0 10px 0 0;
    }

    ul.inbox-nav li a i {
        padding-left: 10px;
        padding-right: inherit;
    }

    ul.labels-info li a i {
        padding-left: 10px;
        padding-right: inherit;
    }

    .inbox-head {
        border-radius: 4px 0 0 0;
    }

    .inbox-head .sr-input {
        border-radius: 0 4px 4px 0;
        float: right;
    }

    .inbox-head .sr-btn {
        border-radius: 4px 0 0 4px;
    }

    .mail-option .btn-group,
    .mail-option .chk-all {
        margin-left: 5px;
        margin-right: inherit;
    }

    .inbox-pagination a.np-btn {
        margin-right: 5px;
        margin-left: inherit;
    }

    .inbox-pagination li span {
        margin-left: 5px;
        margin-right: inherit;
    }

    .attachment-mail ul li {
        float: right;
        margin-left: 10px;
        margin-right: inherit;
    }

    .attachment-mail ul li span {
        float: left;
    }

    .attachment-mail .file-name {
        float: right;
    }

    .mail-chats {
        padding: 10px 0 10px 50px;
    }

    .mail-chats li.chat-persons a span.pro-pic {
        float: right;
        margin-left: 20px;
        margin-right: inherit;
    }

    .mail-chats li.chat-persons.user {
        padding: 5px 15px 0 10px;
    }

    .online {
        margin-right: 20px;
        margin-left: inherit;
    }

    .inbox-message .message-avatar {
        right: 30px;
        left: inherit;
    }

    .inbox-message .message-body {
        margin-right: 85px;
        margin-left: inherit;
    }

    .message-body h5 span {
        margin-right: 5px;
        margin-left: inherit;
    }

    .message-body-heading span {
        float: left;
    }

    .error-img:before {
        left: 0;
        right: inherit;
    }

    .construction .form-control {
        padding-right: 20px;
        padding-left: inherit;
    }

    .ribbon1 {
        right: 10px;
        left: inherit;
    }

    .ribbon1:after {
        border-right: 20px solid transparent;
        border-left: 24px solid transparent;
    }

    .ribbon1 span {
        border-top-left-radius: 8px;
        border-top-right-radius: inherit;
    }

    .ribbon1 span:before {
        right: -6px;
        left: inherit;
    }

    .ribbon1 span:after {
        right: -8px;
        left: inherit;
    }

    .arrow-ribbon {
        right: 0;
        left: inherit;
    }

    .arrow-ribbon.bg-purple:before {
        left: -15px;
        right: inherit;
        border-right: 15px solid #6d33ff;
        border-left: inherit;
    }

    .arrow-ribbon.bg-danger:before {
        left: -15px;
        right: inherit;
        border-right: 15px solid #f84242;
        border-left: inherit;
    }

    .arrow-ribbon.bg-primary:before {
        left: -15px;
        right: inherit;
    }

    .arrow-ribbon.bg-secondary:before {
        left: -15px;
        right: inherit;
        border-right: 15px solid #520995;
        border-left: inherit;
    }

    .arrow-ribbon.bg-success:before {
        left: -15px;
        right: inherit;
        border-right: 15px solid #09d37b;
        border-left: inherit;
    }

    .arrow-ribbon.bg-info:before {
        left: -15px;
        right: inherit;
        border-right: 15px solid #04b2e5;
        border-left: inherit;
    }

    .arrow-ribbon.bg-warning:before {
        left: -15px;
        right: inherit;
        border-right: 15px solid #ffa22b;
        border-left: inherit;
    }

    .arrow-ribbon.bg-pink:before {
        left: -15px;
        right: inherit;
        border-right: 15px solid #f5127b;
        border-left: inherit;
    }

    .arrow-ribbon2 {
        right: -1px;
        left: inherit;
    }

    .arrow-ribbon2:before {
        left: -24px;
        right: inherit;
    }

    .power-ribbon img {
        padding-right: 6px;
        padding-left: inherit;
        transform: rotate(-72deg);
    }

    .power-ribbon-top-left {
        right: -9px;
        left: inherit;
    }

    .power-ribbon-top-left span {
        left: -5px;
        right: initial;
        transform: rotate(45deg);
    }

    .power-ribbon-top-left span i {
        padding-right: 7px;
        padding-left: inherit;
    }

    .ribbon-top-left {
        right: -10px;
        left: inherit;
    }

    .ribbon-top-left::after {
        border-right-color: transparent;
        border-left-color: inherit;
    }

    .ribbon-top-left::before {
        border-right-color: transparent;
        border-left-color: inherit;
        left: 50px;
        right: auto;
    }

    .ribbon-top-left::after {
        right: 0;
        left: inherit;
    }

    .ribbon-top-left span {
        left: -8px;
        right: auto;
        transform: rotate(45deg);
    }

    .ribbon-top-right {
        left: -10px;
        right: inherit;
    }

    .ribbon-top-right::after {
        border-left-color: transparent;
        border-right-color: inherit;
    }

    .ribbon-top-right::before {
        border-left-color: transparent;
        border-right-color: inherit;
        right: 36px;
        left: inherit;
    }

    .ribbon-top-right::after {
        left: 0;
        right: inherit;
    }

    .ribbon-top-right span {
        right: -8px;
        left: inherit;
        transform: rotate(-45deg);
    }

    .ribbon-1 {
        left: 10px;
        right: inherit;
    }

    .ribbon-1:after {
        border-right: 20px solid transparent;
        border-left: 24px solid transparent;
    }

    .ribbon-1 span {
        border-top-left-radius: 8px;
        border-top-right-radius: inherit;
    }

    .ribbon-1 span:before {
        right: -6px;
        left: inherit;
    }

    .ribbon-1 span:after {
        right: -8px;
        left: inherit;
    }

    .ribbon-2 {
        left: 10px;
        right: inherit;
    }

    .ribbon-2:after {
        border-right: 20px solid transparent;
        border-left: 24px solid transparent;
    }

    .ribbon-2 span {
        border-top-left-radius: 8px;
        border-top-right-radius: inherit;
    }

    .ribbon-2 span:before {
        right: -6px;
        left: inherit;
    }

    .ribbon-2 span:after {
        right: -8px;
        left: inherit;
    }

    .ribbon-3 {
        left: 10px;
        right: inherit;
    }

    .ribbon-3:after {
        border-right: 20px solid transparent;
        border-left: 24px solid transparent;
    }

    .ribbon-3 span {
        border-top-left-radius: 8px;
        border-top-right-radius: inherit;
    }

    .ribbon-3 span:before {
        right: -6px;
        left: inherit;
    }

    .ribbon-3 span:after {
        right: -8px;
        left: inherit;
    }

    .arrow-ribbon.bg-secondary:before {
        border-right: 15px solid #0c295a;
        border-left: inherit;
    }

    .user-wideget .border-end {
        border-left: 1px solid #f4f4f4;
        border-right: inherit;
    }

    .weather-card .top .weather-wrapper .mynav .icon {
        float: right;
    }

    .weather-card .top .weather-wrapper .mynav .icon1 {
        float: left;
    }

    .weather-card .top:after {
        right: 0;
        left: inherit;
    }

    .weather-card .bottom .weather-wrapper .forecast li .condition {
        float: left;
    }

    .weather-card .bottom .weather-wrapper .forecast li .condition .temp .deg {
        margin-right: 3px;
        margin-left: inherit;
    }

    .widget-user .widget-user-image {
        right: 50%;
        left: inherit;
        margin-right: -45px;
        margin-left: inherit;
    }

    .widgets-cards .wrp.icon-circle {
        margin-left: 15px;
        margin-right: inherit;
    }

    .widget-line-list li {
        padding: 5px 15px 0 20px;
    }

    .wideget-user-info .wideget-user-warap .wideget-user-warap-r {
        margin-right: 10rem;
        margin-left: inherit;
    }

    .widget-spec li i {
        margin-left: 0.5rem;
        margin-right: inherit;
    }

    .widget-info-end {
        text-align: left;
        float: left;
    }

    @media (max-width: 992px) {
        .widget-info-end {
            text-align: right;
            float: right;
        }
    }

    .wideget-user-tab.wideget-user-tab3 .tabs-menu1 ul li:first-child a {
        margin-right: 0;
        margin-left: inherit;
    }

    .wizard>.steps>ul li .title {
        margin-right: 5px;
        margin: 0;
    }

    .wizard>.steps>ul li+li {
        margin-right: 5px;
        margin-left: inherit;
    }

    .wizard>.steps a .number,
    .wizard>.steps a:hover .number,
    .wizard>.steps a:active .number {
        margin-left: 5px;
        margin-right: inherit
    }

    @media (min-width: 576px) {
        .wizard>.steps>ul li+li {
            margin-right: 20px;
            margin-left: 0;
        }

        .wizard>.steps>ul li .title {
            margin-right: 10px;
            margin-left: 0;
        }

        .wizard.vertical>.actions {
            float: left;
            border-right: 1px solid $border;
            border-left: inherit;
        }

        .wizard.vertical>.content {
            float: left;
            border-right: 1px solid $border;
            border-left: inherit;
        }

        .wizard.vertical>.steps {
            float: right;
        }

        .wizard.vertical>.steps ul li+li {
            margin-right: 0;
            margin-left: inherit;
        }
    }

    @media (min-width: 992px) {
        .wizard>.steps>ul li+li {
            margin-right: 30px;
            margin-left: inherit;
        }
    }

    .wizard-style-2>.steps>ul a .title,
    .wizard-style-2>.steps>ul a:hover .title,
    .wizard-style-2>.steps>ul a:active .title {
        margin-left: 20px;
        margin-right: inherit;
    }

    .br-ts-7 {
        border-top-right-radius: 7px !important;
        border-top-left-radius: 0 !important;
    }

    .br-ts-10 {
        border-top-right-radius: 1rem !important;
        border-top-left-radius: 0 !important;
    }

    .br-bs-7 {
        border-bottom-right-radius: 7px !important;
        border-bottom-left-radius: 0 !important;
    }

    .br-bs-10 {
        border-bottom-right-radius: 1rem !important;
        border-bottom-left-radius: 0 !important;
    }

    .br-te-7 {
        border-top-left-radius: 7px !important;
        border-top-right-radius: 0 !important;
    }

    .br-te-10 {
        border-top-left-radius: 1rem !important;
        border-top-right-radius: 0 !important;
    }

    .br-be-7 {
        border-bottom-left-radius: 7px !important;
        border-bottom-right-radius: 0 !important;
    }

    .br-be-10 {
        border-bottom-left-radius: 1rem !important;
        border-bottom-right-radius: 0 !important;
    }

    .br-ts-0 {
        border-top-right-radius: 0 !important;
        border-top-left-radius: 7px !important;
    }

    .br-bs-0 {
        border-bottom-right-radius: 0 !important;
        border-bottom-left-radius: 7px !important;
    }

    .br-te-0 {
        border-top-left-radius: 0 !important;
        border-top-right-radius: 7px !important;
    }

    .br-be-0 {
        border-bottom-left-radius: 0 !important;
        border-bottom-right-radius: 7px !important;
    }

    .br-ts-3 {
        border-top-right-radius: 3px !important;
        border-top-left-radius: inherit !important;
    }

    .br-bs-3 {
        border-bottom-right-radius: 3px !important;
        border-bottom-left-radius: inherit !important;
    }

    .br-te-3 {
        border-top-left-radius: 3px !important;
        border-top-right-radius: inherit !important;
    }

    .br-be-3 {
        border-bottom-left-radius: 3px !important;
        border-bottom-right-radius: inherit !important;
    }

    .br-ts-2 {
        border-top-right-radius: 2px !important;
        border-top-left-radius: inherit !important;
    }

    .br-bs-2 {
        border-bottom-right-radius: 2px !important;
        border-bottom-left-radius: inherit !important;
    }

    .br-te-2 {
        border-top-left-radius: 2px !important;
        border-top-right-radius: inherit !important;
    }

    .br-be-2 {
        border-bottom-left-radius: 2px !important;
        border-bottom-right-radius: inherit !important;
    }

    @media (min-width: 992px) {

        .br-ts-md-2 {
            border-top-right-radius: 2px !important;
            border-top-left-radius: inherit !important;
        }

        .br-bs-md-2 {
            border-bottom-right-radius: 2px !important;
            border-bottom-left-radius: inherit !important;
        }

        .br-te-md-2 {
            border-top-left-radius: 2px !important;
            border-top-right-radius: inherit !important;
        }

        .br-be-md-2 {
            border-bottom-left-radius: 2px !important;
            border-bottom-right-radius: inherit !important;
        }

        .br-ts-md-0 {
            border-top-right-radius: 0 !important;
            border-top-left-radius: 7px !important;
        }

        .br-bs-md-0 {
            border-bottom-right-radius: 0 !important;
            border-bottom-left-radius: 7px !important;
        }

        .br-te-md-0 {
            border-top-left-radius: 0 !important;
            border-top-right-radius: 7px !important;
        }

        .br-be-md-0 {
            border-bottom-left-radius: 0 !important;
            border-bottom-right-radius: 7px !important;
        }
    }

    .border-end {
        border-left: 1px solid $border !important;
        border-right: initial !important;
    }

    .border-start {
        border-right: 1px solid $border !important;
        border-left: initial !important;
    }

    .border-end-0 {
        border-left: 0 !important;
        border-right: 1px solid $border !important;
    }

    .border-start-0 {
        border-right: 0 !important;
        border-left: 1px solid $border !important;
    }

    &.dark-theme .border-end-0 {
        border-left: 0 !important;
        border-right: 1px solid $dark-border !important;
    }

    &.dark-theme .border-start-0 {
        border-right: 0 !important;
        border-left: 1px solid $dark-border !important;
    }

    .rounded-end {
        border-top-left-radius: 3px !important;
        border-top-right-radius: inherit !important;
        border-bottom-left-radius: 3px !important;
        border-bottom-right-radius: inherit !important;
    }

    .rounded-start {
        border-top-right-radius: 3px !important;
        border-top-left-radius: inherit !important;
        border-bottom-right-radius: 3px !important;
        border-bottom-left-radius: inherit !important;
    }

    .float-start {
        float: right !important;
    }

    .float-end {
        float: left !important;
    }

    @media (min-width: 576px) {
        .float-sm-start {
            float: right !important;
        }

        .float-sm-end {
            float: left !important;
        }
    }

    @media (min-width: 768px) {
        .float-md-start {
            float: right !important;
        }

        .float-md-end {
            float: left !important;
        }
    }

    @media (min-width: 992px) {
        .float-lg-start {
            float: right !important;
        }

        .float-lg-end {
            float: left !important;
        }
    }

    @media (min-width: 1280px) {
        .float-xl-start {
            float: right !important;
        }

        .float-xl-end {
            float: left !important;
        }
    }

    /*------  Margins  -------*/

    .me-0 {
        margin-left: 0 !important;
        margin-right: inherit !important;
    }

    .ms-0 {
        margin-right: 0 !important;
        margin-left: inherit !important;
    }

    .me-1 {
        margin-left: 0.25rem !important;
        margin-right: initial !important;
    }

    .ms-1 {
        margin-right: 0.25rem !important;
        margin-left: inherit !important;
    }

    .me-2 {
        margin-left: 0.5rem !important;
        margin-right: inherit !important;
    }

    .ms-2 {
        margin-right: 0.5rem !important;
        margin-left: inherit !important;
    }

    .me-3 {
        margin-left: 0.75rem !important;
        margin-right: inherit !important;
    }

    .ms-3 {
        margin-right: 0.75rem !important;
        margin-left: inherit !important;
    }

    .me-4 {
        margin-left: 1rem !important;
        margin-right: inherit !important;
    }

    .ms-4 {
        margin-right: 1rem !important;
        margin-left: inherit !important;
    }

    .me-5 {
        margin-left: 1.5rem !important;
        margin-right: inherit !important;
    }

    .ms-5 {
        margin-right: 1.5rem !important;
        margin-left: inherit !important;
    }

    .me-6 {
        margin-left: 2rem !important;
        margin-right: inherit !important;
    }

    .ms-6 {
        margin-right: 2rem !important;
        margin-left: inherit !important;
    }

    .me-7 {
        margin-left: 3rem !important;
        margin-right: inherit !important;
    }

    .ms-7 {
        margin-right: 3rem !important;
        margin-left: inherit !important;
    }

    .me-8 {
        margin-left: 4rem !important;
        margin-right: inherit !important;
    }

    .ms-8 {
        margin-right: 4rem !important;
        margin-left: inherit !important;
    }

    .me-9 {
        margin-left: 6rem !important;
        margin-right: inherit !important;
    }

    .ms-9 {
        margin-right: 6rem !important;
        margin-left: inherit !important;
    }

    .me-auto {
        margin-left: auto !important;
        margin-right: inherit !important;
    }

    .ms-auto {
        margin-right: auto !important;
        margin-left: inherit !important;
    }

    @media (min-width: 576px) {

        .me-sm-0 {
            margin-left: 0 !important;
            margin-right: inherit !important;
        }

        .ms-sm-0 {
            margin-right: 0 !important;
            margin-left: inherit !important;
        }

        .me-sm-1 {
            margin-left: 0.25rem !important;
            margin-right: inherit !important;
        }

        .ms-sm-1 {
            margin-right: 0.25rem !important;
            margin-left: inherit !important;
        }

        .me-sm-2 {
            margin-left: 0.5rem !important;
            margin-right: inherit !important;
        }

        .ms-sm-2 {
            margin-right: 0.5rem !important;
            margin-left: inherit !important;
        }

        .me-sm-3 {
            margin-left: 0.75rem !important;
            margin-right: inherit !important;
        }

        .ms-sm-3 {
            margin-right: 0.75rem !important;
            margin-left: inherit !important;
        }

        .me-sm-4 {
            margin-left: 1rem !important;
            margin-right: inherit !important;
        }

        .ms-sm-4 {
            margin-right: 1rem !important;
            margin-left: inherit !important;
        }

        .me-sm-5 {
            margin-left: 1.5rem !important;
            margin-right: inherit !important;
        }

        .ms-sm-5 {
            margin-right: 1.5rem !important;
            margin-left: inherit !important;
        }

        .me-sm-6 {
            margin-left: 2rem !important;
            margin-right: inherit !important;
        }

        .ms-sm-6 {
            margin-right: 2rem !important;
            margin-left: inherit !important;
        }

        .me-sm-7 {
            margin-left: 3rem !important;
            margin-right: inherit !important;
        }

        .ms-sm-7 {
            margin-right: 3rem !important;
            margin-left: inherit !important;
        }

        .me-sm-8 {
            margin-left: 4rem !important;
            margin-right: inherit !important;
        }

        .ms-sm-8 {
            margin-right: 4rem !important;
            margin-left: inherit !important;
        }

        .me-sm-9 {
            margin-left: 6rem !important;
            margin-right: inherit !important;
        }

        .ms-sm-9 {
            margin-right: 6rem !important;
            margin-left: inherit !important;
        }

        .me-sm-auto {
            margin-left: auto !important;
            margin-right: inherit !important;
        }

        .ms-sm-auto {
            margin-right: auto !important;
            margin-left: inherit !important;
        }
    }

    @media (min-width: 768px) {

        .me-md-0 {
            margin-left: 0 !important;
            margin-right: inherit !important;
        }

        .ms-md-0 {
            margin-right: 0 !important;
            margin-left: inherit !important;
        }

        .me-md-1 {
            margin-left: 0.25rem !important;
            margin-right: inherit !important;
        }

        .ms-md-1 {
            margin-right: 0.25rem !important;
            margin-left: inherit !important;
        }

        .me-md-2 {
            margin-left: 0.5rem !important;
            margin-right: inherit !important;
        }

        .ms-md-2 {
            margin-right: 0.5rem !important;
            margin-left: inherit !important;
        }

        .me-md-3 {
            margin-left: 0.75rem !important;
            margin-right: inherit !important;
        }

        .ms-md-3 {
            margin-right: 0.75rem !important;
            margin-left: inherit !important;
        }

        .me-md-4 {
            margin-left: 1rem !important;
            margin-right: inherit !important;
        }

        .ms-md-4 {
            margin-right: 1rem !important;
            margin-left: inherit !important;
        }

        .me-md-5 {
            margin-left: 1.5rem !important;
            margin-right: inherit !important;
        }

        .ms-md-5 {
            margin-right: 1.5rem !important;
            margin-left: inherit !important;
        }

        .me-md-6 {
            margin-left: 2rem !important;
            margin-right: inherit !important;
        }

        .ms-md-6 {
            margin-right: 2rem !important;
            margin-left: inherit !important;
        }

        .me-md-7 {
            margin-left: 3rem !important;
            margin-right: inherit !important;
        }

        .ms-md-7 {
            margin-right: 3rem !important;
            margin-left: inherit !important;
        }

        .me-md-8 {
            margin-left: 4rem !important;
            margin-right: inherit !important;
        }

        .ms-md-8 {
            margin-right: 4rem !important;
            margin-left: inherit !important;
        }

        .me-md-9 {
            margin-left: 6rem !important;
            margin-right: inherit !important;
        }

        .ms-md-9 {
            margin-right: 6rem !important;
            margin-left: inherit !important;
        }

        .me-md-auto {
            margin-left: auto !important;
            margin-right: inherit !important;
        }

        .ms-md-auto {
            margin-right: auto !important;
            margin-left: inherit !important;
        }
    }

    @media (min-width: 992px) {

        .me-lg-0 {
            margin-left: 0 !important;
            margin-right: inherit !important;
        }

        .ms-lg-0 {
            margin-right: 0 !important;
            margin-left: inherit !important;
        }

        .me-lg-1 {
            margin-left: 0.25rem !important;
            margin-right: inherit !important;
        }

        .ms-lg-1 {
            margin-right: 0.25rem !important;
            margin-left: inherit !important;
        }

        .me-lg-2 {
            margin-left: 0.5rem !important;
            margin-right: inherit !important;
        }

        .ms-lg-2 {
            margin-right: 0.5rem !important;
            margin-left: inherit !important;
        }

        .me-lg-3 {
            margin-left: 0.75rem !important;
            margin-right: inherit !important;
        }

        .ms-lg-3 {
            margin-right: 0.75rem !important;
            margin-left: inherit !important;
        }

        .me-lg-4 {
            margin-left: 1rem !important;
            margin-right: inherit !important;
        }

        .ms-lg-4 {
            margin-right: 1rem !important;
            margin-left: inherit !important;
        }

        .me-lg-5 {
            margin-left: 1.5rem !important;
            margin-right: inherit !important;
        }

        .ms-lg-5 {
            margin-right: 1.5rem !important;
            margin-left: inherit !important;
        }

        .me-lg-6 {
            margin-left: 2rem !important;
            margin-right: inherit !important;
        }

        .ms-lg-6 {
            margin-right: 2rem !important;
            margin-left: inherit !important;
        }

        .me-lg-7 {
            margin-left: 3rem !important;
            margin-right: inherit !important;
        }

        .ms-lg-7 {
            margin-right: 3rem !important;
            margin-left: inherit !important;
        }

        .me-lg-8 {
            margin-left: 4rem !important;
            margin-right: inherit !important;
        }

        .ms-lg-8 {
            margin-right: 4rem !important;
            margin-left: inherit !important;
        }

        .me-lg-9 {
            margin-left: 6rem !important;
            margin-right: inherit !important;
        }

        .ms-lg-9 {
            margin-right: 6rem !important;
            margin-left: inherit !important;
        }

        .me-lg-auto {
            margin-left: auto !important;
            margin-right: inherit !important;
        }

        .ms-lg-auto {
            margin-right: auto !important;
            margin-left: inherit !important;
        }
    }

    @media (min-width: 1280px) {

        .me-xl-0 {
            margin-left: 0 !important;
            margin-right: inherit !important;
        }

        .ms-xl-0 {
            margin-right: 0 !important;
            margin-left: inherit !important;
        }

        .me-xl-1 {
            margin-left: 0.25rem !important;
            margin-right: inherit !important;
        }

        .ms-xl-1 {
            margin-right: 0.25rem !important;
            margin-left: inherit !important;
        }

        .me-xl-2 {
            margin-left: 0.5rem !important;
            margin-right: inherit !important;
        }

        .ms-xl-2 {
            margin-right: 0.5rem !important;
            margin-left: inherit !important;
        }

        .me-xl-3 {
            margin-left: 0.75rem !important;
            margin-right: inherit !important;
        }

        .ms-xl-3 {
            margin-right: 0.75rem !important;
            margin-left: inherit !important;
        }

        .me-xl-4 {
            margin-left: 1rem !important;
            margin-right: inherit !important;
        }

        .ms-xl-4 {
            margin-right: 1rem !important;
            margin-left: inherit !important;
        }

        .me-xl-5 {
            margin-left: 1.5rem !important;
            margin-right: inherit !important;
        }

        .ms-xl-5 {
            margin-right: 1.5rem !important;
            margin-left: inherit !important;
        }

        .me-xl-6 {
            margin-left: 2rem !important;
            margin-right: inherit !important;
        }

        .ms-xl-6 {
            margin-right: 2rem !important;
            margin-left: inherit !important;
        }

        .me-xl-7 {
            margin-left: 3rem !important;
            margin-right: inherit !important;
        }

        .ms-xl-7 {
            margin-right: 3rem !important;
            margin-left: inherit !important;
        }

        .me-xl-8 {
            margin-left: 4rem !important;
            margin-right: inherit !important;
        }

        .ms-xl-8 {
            margin-right: 4rem !important;
            margin-left: inherit !important;
        }

        .me-xl-9 {
            margin-left: 6rem !important;
            margin-right: inherit !important;
        }

        .ms-xl-9 {
            margin-right: 6rem !important;
            margin-left: inherit !important;
        }

        .me-xl-auto {
            margin-left: auto !important;
            margin-right: inherit !important;
        }

        .ms-xl-auto {
            margin-right: auto !important;
            margin-left: inherit !important;
        }
    }

    /*------ Paddings -------*/

    .pe-0 {
        padding-left: 0 !important;
        padding-right: inherit !important;
    }

    .ps-0 {
        padding-right: 0 !important;
        padding-left: inherit !important;
    }

    .pe-1 {
        padding-left: 0.25rem !important;
        padding-right: inherit !important;
    }

    .ps-1 {
        padding-right: 0.25rem !important;
        padding-left: inherit !important;
    }

    .pe-2 {
        padding-left: 0.5rem !important;
        padding-right: inherit !important;
    }

    .ps-2 {
        padding-right: 0.5rem !important;
        padding-left: inherit !important;
    }

    .pe-3 {
        padding-left: 0.75rem !important;
        padding-right: inherit !important;
    }

    .ps-3 {
        padding-right: 0.75rem !important;
        padding-left: inherit !important;
    }

    .pe-4 {
        padding-left: 1rem !important;
        padding-right: inherit !important;
    }

    .ps-4 {
        padding-right: 1rem !important;
        padding-left: inherit !important;
    }

    .pe-5 {
        padding-left: 1.5rem !important;
        padding-right: inherit !important;
    }

    .ps-5 {
        padding-right: 1.5rem !important;
        padding-left: inherit !important;
    }

    .pe-6 {
        padding-left: 2rem !important;
        padding-right: inherit !important;
    }

    .ps-6 {
        padding-right: 2rem !important;
        padding-left: inherit !important;
    }

    .pe-7 {
        padding-left: 3rem !important;
        padding-right: inherit !important;
    }

    .ps-7 {
        padding-right: 3rem !important;
        padding-left: inherit !important;
    }

    .pe-8 {
        padding-left: 4rem !important;
        padding-right: inherit !important;
    }

    .ps-8 {
        padding-right: 4rem !important;
        padding-left: inherit !important;
    }

    .pe-9 {
        padding-left: 6rem !important;
        padding-right: inherit !important;
    }

    .ps-9 {
        padding-right: 6rem !important;
        padding-left: inherit !important;
    }

    .pe-10 {
        padding-left: 9.3rem !important;
        padding-right: inherit !important;
    }

    .ps-10 {
        padding-right: 9.3rem !important;
        padding-left: inherit !important;
    }

    @media (min-width: 576px) {

        .pe-sm-0 {
            padding-left: 0 !important;
            padding-right: inherit !important;
        }

        .ps-sm-0 {
            padding-right: 0 !important;
            padding-left: inherit !important;
        }

        .pe-sm-1 {
            padding-left: 0.25rem !important;
            padding-right: inherit !important;
        }

        .ps-sm-1 {
            padding-right: 0.25rem !important;
            padding-left: inherit !important;
        }

        .pe-sm-2 {
            padding-left: 0.5rem !important;
            padding-right: inherit !important;
        }

        .ps-sm-2 {
            padding-right: 0.5rem !important;
            padding-left: inherit !important;
        }

        .pe-sm-3 {
            padding-left: 0.75rem !important;
            padding-right: inherit !important;
        }

        .ps-sm-3 {
            padding-right: 0.75rem !important;
            padding-left: inherit !important;
        }

        .pe-sm-4 {
            padding-left: 1rem !important;
            padding-right: inherit !important;
        }

        .ps-sm-4 {
            padding-right: 1rem !important;
            padding-left: inherit !important;
        }

        .pe-sm-5 {
            padding-left: 1.5rem !important;
            padding-right: inherit !important;
        }

        .ps-sm-5 {
            padding-right: 1.5rem !important;
            padding-left: inherit !important;
        }

        .pe-sm-6 {
            padding-left: 2rem !important;
            padding-right: inherit !important;
        }

        .ps-sm-6 {
            padding-right: 2rem !important;
            padding-left: inherit !important;
        }

        .pe-sm-7 {
            padding-left: 3rem !important;
            padding-right: inherit !important;
        }

        .ps-sm-7 {
            padding-right: 3rem !important;
            padding-left: inherit !important;
        }

        .pe-sm-8 {
            padding-left: 4rem !important;
            padding-right: inherit !important;
        }

        .ps-sm-8 {
            padding-right: 4rem !important;
            padding-left: inherit !important;
        }

        .pe-sm-9 {
            padding-left: 6rem !important;
            padding-right: inherit !important;
        }

        .ps-sm-9 {
            padding-right: 6rem !important;
            padding-left: inherit !important;
        }
    }

    @media (min-width: 768px) {

        .pe-md-0 {
            padding-left: 0 !important;
            padding-right: inherit !important;
        }

        .ps-md-0 {
            padding-right: 0 !important;
            padding-left: inherit !important;
        }

        .pe-md-1 {
            padding-left: 0.25rem !important;
            padding-right: inherit !important;
        }

        .ps-md-1 {
            padding-right: 0.25rem !important;
            padding-left: inherit !important;
        }

        .pe-md-2 {
            padding-left: 0.5rem !important;
            padding-right: inherit !important;
        }

        .ps-md-2 {
            padding-right: 0.5rem !important;
            padding-left: inherit !important;
        }

        .pe-md-3 {
            padding-left: 0.75rem !important;
            padding-right: inherit !important;
        }

        .ps-md-3 {
            padding-right: 0.75rem !important;
            padding-left: inherit !important;
        }

        .pe-md-4 {
            padding-left: 1rem !important;
            padding-right: inherit !important;
        }

        .ps-md-4 {
            padding-right: 1rem !important;
            padding-left: inherit !important;
        }

        .pe-md-5 {
            padding-left: 1.5rem !important;
            padding-right: inherit !important;
        }

        .ps-md-5 {
            padding-right: 1.5rem !important;
            padding-left: inherit !important;
        }

        .pe-md-6 {
            padding-left: 2rem !important;
            padding-right: inherit !important;
        }

        .ps-md-6 {
            padding-right: 2rem !important;
            padding-left: inherit !important;
        }

        .pe-md-7 {
            padding-left: 3rem !important;
            padding-right: inherit !important;
        }

        .ps-md-7 {
            padding-right: 3rem !important;
            padding-left: inherit !important;
        }

        .pe-md-8 {
            padding-left: 4rem !important;
            padding-right: inherit !important;
        }

        .ps-md-8 {
            padding-right: 4rem !important;
            padding-left: inherit !important;
        }

        .pe-md-9 {
            padding-left: 6rem !important;
            padding-right: inherit !important;
        }

        .ps-md-9 {
            padding-right: 6rem !important;
            padding-left: inherit !important;
        }
    }

    @media (min-width: 992px) {

        .pe-lg-0 {
            padding-left: 0 !important;
            padding-right: inherit !important;
        }

        .ps-lg-0 {
            padding-right: 0 !important;
            padding-left: inherit !important;
        }

        .pe-lg-1 {
            padding-left: 0.25rem !important;
            padding-right: inherit !important;
        }

        .ps-lg-1 {
            padding-right: 0.25rem !important;
            padding-left: inherit !important;
        }

        .pe-lg-2 {
            padding-left: 0.5rem !important;
            padding-right: inherit !important;
        }

        .ps-lg-2 {
            padding-right: 0.5rem !important;
            padding-left: inherit !important;
        }

        .pe-lg-3 {
            padding-left: 0.75rem !important;
            padding-right: inherit !important;
        }

        .ps-lg-3 {
            padding-right: 0.75rem !important;
            padding-left: inherit !important;
        }

        .pe-lg-4 {
            padding-left: 1rem !important;
            padding-right: inherit !important;
        }

        .ps-lg-4 {
            padding-right: 1rem !important;
            padding-left: inherit !important;
        }

        .pe-lg-5 {
            padding-left: 1.5rem !important;
            padding-right: inherit !important;
        }

        .ps-lg-5 {
            padding-right: 1.5rem !important;
            padding-left: inherit !important;
        }

        .pe-lg-6 {
            padding-left: 2rem !important;
            padding-right: inherit !important;
        }

        .ps-lg-6 {
            padding-right: 2rem !important;
            padding-left: inherit !important;
        }

        .pe-lg-7 {
            padding-left: 3rem !important;
            padding-right: inherit !important;
        }

        .ps-lg-7 {
            padding-right: 3rem !important;
            padding-left: inherit !important;
        }

        .pe-lg-8 {
            padding-left: 4rem !important;
            padding-right: inherit !important;
        }

        .ps-lg-8 {
            padding-right: 4rem !important;
            padding-left: inherit !important;
        }

        .pe-lg-9 {
            padding-left: 6rem !important;
            padding-right: inherit !important;
        }

        .ps-lg-9 {
            padding-right: 6rem !important;
            padding-left: inherit !important;
        }
    }

    @media (min-width: 1280px) {

        .pe-xl-0 {
            padding-left: 0 !important;
            padding-right: inherit !important;
        }

        .ps-xl-0 {
            padding-right: 0 !important;
            padding-left: inherit !important;
        }

        .pe-xl-1 {
            padding-left: 0.25rem !important;
            padding-right: inherit !important;
        }

        .ps-xl-1 {
            padding-right: 0.25rem !important;
            padding-left: inherit !important;
        }

        .pe-xl-2 {
            padding-left: 0.5rem !important;
            padding-right: inherit !important;
        }

        .ps-xl-2 {
            padding-right: 0.5rem !important;
            padding-left: inherit !important;
        }

        .pe-xl-3 {
            padding-left: 0.75rem !important;
            padding-right: inherit !important;
        }

        .ps-xl-3 {
            padding-right: 0.75rem !important;
            padding-left: inherit !important;
        }

        .pe-xl-4 {
            padding-left: 1rem !important;
            padding-right: inherit !important;
        }

        .ps-xl-4 {
            padding-right: 1rem !important;
            padding-left: inherit !important;
        }

        .pe-xl-5 {
            padding-left: 1.5rem !important;
            padding-right: inherit !important;
        }

        .ps-xl-5 {
            padding-right: 1.5rem !important;
            padding-left: inherit !important;
        }

        .pe-xl-6 {
            padding-left: 2rem !important;
            padding-right: inherit !important;
        }

        .ps-xl-6 {
            padding-right: 2rem !important;
            padding-left: inherit !important;
        }

        .pe-xl-7 {
            padding-left: 3rem !important;
            padding-right: inherit !important;
        }

        .ps-xl-7 {
            padding-right: 3rem !important;
            padding-left: inherit !important;
        }

        .pe-xl-8 {
            padding-left: 4rem !important;
            padding-right: inherit !important;
        }

        .ps-xl-8 {
            padding-right: 4rem !important;
            padding-left: inherit !important;
        }

        .pe-xl-9 {
            padding-left: 6rem !important;
            padding-right: inherit !important;
        }

        .ps-xl-9 {
            padding-right: 6rem !important;
            padding-left: inherit !important;
        }
    }

    .text-start {
        text-align: right !important;
    }

    .text-end {
        text-align: left !important;
    }

    @media (min-width: 576px) {
        .text-sm-start {
            text-align: right !important;
        }

        .text-sm-end {
            text-align: left !important;
        }
    }

    @media (min-width: 768px) {
        .text-md-start {
            text-align: right !important;
        }

        .text-md-end {
            text-align: left !important;
        }
    }

    @media (min-width: 992px) {
        .text-lg-start {
            text-align: right !important;
        }

        .text-lg-end {
            text-align: left !important;
        }
    }

    @media (min-width: 1280px) {
        .text-xl-start {
            text-align: right !important;
        }

        .text-xl-end {
            text-align: left !important;
        }
    }

    .header-main .dropdown-item i {
        margin-left: 0.5rem;
        margin-right: 0;
    }

    [type="email"], 
    [type="number"], 
    [type="tel"], 
    [type="url"] {
        direction: rtl;
    }

    .my-dash {
        .side-menu, .child-sub-menu  {
            padding: 0;
        }
        .side-menu__icon , .slide-menu {
            margin: 0;
        }
    }

    @media (max-width: 992px) {
        .header-search .header-icons {
            float: none !important;
        }
    }

    @media (min-width: 1280px) {
        .item7-card-img {
            border-bottom-right-radius: 7px;
            border-bottom-left-radius: 7px;
            border-top-right-radius: 7px;
            border-top-left-radius: 7px;
        }
    }

    #mixed-error div, 
    #candlechart div, 
    #barlinechart div, 
    #multibar div, 
    #demodiv div {
        text-align: right !important;
    }
    .bs-tooltip-auto[data-popper-placement^="right"] .tooltip-arrow, 
    .bs-tooltip-end .tooltip-arrow {
        right: auto;
        left: inherit;
    }
    .bs-tooltip-auto[data-popper-placement^="right"] .tooltip-arrow::before, 
    .bs-tooltip-end .tooltip-arrow::before {
        left: 0;
        right: inherit;
        border-width: 0.4rem 0.4rem 0.4rem 0;
        border-left-color: transparent;
        border-right-color: #000;
    }

    .bs-tooltip-auto[data-popper-placement^=left] .tooltip-arrow, 
    .bs-tooltip-start .tooltip-arrow {
        right: 0;
        left: auto;
    }
    .bs-tooltip-auto[data-popper-placement^=left] .tooltip-arrow::before, 
    .bs-tooltip-start .tooltip-arrow::before {
        right: auto;
        left: -1px;
        border-width: 0.4rem 0 0.4rem 0.4rem;
        border-right-color: transparent;
        border-left-color: #000;
    }

    .bs-popover-auto[data-popper-placement^=right]>.popover-arrow, .bs-popover-end>.popover-arrow {
        left: calc(-0.5rem - 1px);
        right: inherit;
    }
    .bs-popover-auto[data-popper-placement^=right]>.popover-arrow::before, .bs-popover-end>.popover-arrow::before {
        left: 0;
        right: inherit;
        border-width: 0.5rem 0.5rem 0.5rem 0;
        border-right-color: rgba(0,0,0,.25);
        border-left-color: transparent;
    }
    .bs-popover-auto[data-popper-placement^=right]>.popover-arrow::after, .bs-popover-end>.popover-arrow::after {
        left: 1px;
        right: inherit;
        border-width: 0.5rem 0.5rem 0.5rem 0;
        border-right-color: #fff;
        border-left-color: transparent;
    }
    .bs-popover-auto[data-popper-placement^=left]>.popover-arrow, .bs-popover-start>.popover-arrow {
        right: calc(-0.5rem - 1px);
        left: inherit;
    }
    .bs-popover-auto[data-popper-placement^=left]>.popover-arrow::before, .bs-popover-start>.popover-arrow::before {
        right: 0;
        left: inherit;
        border-width: 0.5rem 0 0.5rem 0.5rem;
        border-left-color: rgba(0,0,0,.25);
        border-right-color: transparent;
    }
    .bs-popover-auto[data-popper-placement^=left]>.popover-arrow::after, .bs-popover-start>.popover-arrow::after {
        right: 1px;
        left: inherit;
        border-width: 0.5rem 0 0.5rem 0.5rem;
        border-left-color: #fff;
        border-right-color: transparent;
    }

    .carousel-item-start.active,
    .carousel-item-prev:not(.carousel-item-end) {
        transform: translateX(-100%) !important;
    }

    .active.carousel-item-start,
    .carousel-item-prev:not(.carousel-item-end) {
        transform: translateX(100%) !important;
    }

    .active.carousel-item-end {
        transform: translateX(-100%) !important;
    }

    .carousel-item-next:not(.carousel-item-start) {
        transform: translateX(-100%) !important;
        transform: translateX(-100%) !important;
    }

    .price svg text {
        transform: matrix(1, 0, 0, 1, 300.7256, 116.2686);
    }

    .dropdown-icon {
        margin-right: -0.5rem;
        margin-left: 0.5rem;
    }

    @media screen and (max-width: 575px){
        .video-img:before {
            right: -5px;
            left: 0;
        }
    }
    @media screen and (max-width: 640px) {
        .dataTables_wrapper .dataTables_length {
            float: none !important;
            text-align: center;
        }
    }

    @media screen and (max-width: 767px) {
        div.dataTables_wrapper div.dataTables_length, div.dataTables_wrapper div.dataTables_filter, div.dataTables_wrapper div.dataTables_info {
            text-align: center !important;
        }
    }

    @media screen and (max-width: 640px) {
        .dataTables_wrapper .dataTables_filter {
            float: none !important;
        }
    }

    input.knob {
        margin-right: -77px !important;
        margin-left: inherit !important;
    }
    
}

// Plugins //

.rtl {
    
    .accordionjs .acc_section .acc_head h3 {
        padding-right: 25px;
        padding-left: inherit;
    }

    .accordionjs .acc_section.acc_active .acc_head h3:before {
        right: 10px;
        left: inherit;
    }

    .accordionjs .acc_section .acc_head h3:before {
        right: 10px;
        left: inherit;
    }

    .c3-tooltip th {
        text-align: right;
    }

    .c3-tooltip td>span {
        margin-left: 8px;
        margin-right: inherit;
    }

    .c3-tooltip td.value {
        text-align: left;
    }

    .typing_loader {
        right: -12px;
        left: inherit;
    }

    form.convFormDynamic textarea.userInputDynamic,
    form.convFormDynamic input.userInputDynamic {
        float: right;
        margin-left: 2.5%;
        margin-right: inherit;
    }

    div.conv-form-wrapper:before {
        right: 0;
        left: inherit;
    }

    div.conv-form-wrapper div#messages div.message {
        float: left;
    }

    div.conv-form-wrapper div#messages div.message.to {
        float: right;
        border-top-right-radius: 0;
        border-top-left-radius: inherit;
    }

    div.conv-form-wrapper div#messages div.message.from {
        border-top-left-radius: 0;
        border-top-right-radius: 20px;
    }

    form.convFormDynamic button.submit {
        float: left;
    }

    div.dataTables_wrapper div.dataTables_length label {
        text-align: right;
    }

    div.dataTables_wrapper div.dataTables_filter {
        text-align: left;
    }

    div.dataTables_wrapper div.dataTables_filter label {
        text-align: right;
    }

    div.dataTables_wrapper div.dataTables_filter input {
        margin-right: 0.5em;
        margin-left: inherit;
    }

    div.dataTables_wrapper div.dataTables_paginate {
        text-align: left;
    }

    div.dataTables_wrapper div.dataTables_processing {
        right: 50%;
        left: inherit;
        margin-right: -100px;
        margin-left: inherit;
    }

    table.dataTable>thead>tr>th:not(.sorting_disabled),
    table.dataTable>thead>tr>td:not(.sorting_disabled) {
        padding-left: 30px;
        padding-right: 18px;
    }

    table.dataTable>thead .sorting:before,
    table.dataTable>thead .sorting_asc:before,
    table.dataTable>thead .sorting_desc:before,
    table.dataTable>thead .sorting_asc_disabled:before,
    table.dataTable>thead .sorting_desc_disabled:before {
        left: 1em;
        right: inherit;
    }

    table.dataTable>thead .sorting:after,
    table.dataTable>thead .sorting_asc:after,
    table.dataTable>thead .sorting_desc:after,
    table.dataTable>thead .sorting_asc_disabled:after,
    table.dataTable>thead .sorting_desc_disabled:after {
        left: 0.5em;
        right: inherit;
    }

    table.dataTable.table-sm>thead>tr>th:not(.sorting_disabled) {
        padding-left: 20px;
        padding-right: inherit;
    }

    table.dataTable.table-sm .sorting:before,
    table.dataTable.table-sm .sorting_asc:before,
    table.dataTable.table-sm .sorting_desc:before {
        left: 0.85em;
        right: inherit;
    }

    table.table-bordered.dataTable {
        border-left-width: 0;
        border-right-width: 1px;
    }

    table.table-bordered.dataTable th,
    table.table-bordered.dataTable td,
    table.table-bordered.dataTable th:first-child,
    table.table-bordered.dataTable td:first-child {
        border-right-width: 0;
        border-left-width: 1px;
    }

    table.table-bordered.dataTable th:last-child,
    table.table-bordered.dataTable td:last-child {
        border-left-width: 1px;
        border-right-width: inherit;
    }

    div.table-responsive>div.dataTables_wrapper>div.row>div[class^=col-]:first-child {
        padding-right: 0;
        padding-left: 0.75rem;
    }

    div.table-responsive>div.dataTables_wrapper>div.row>div[class^=col-]:last-child {
        padding-left: 0;
        padding-right: 0.75rem;
    }

    table.dataTable.cell-border tbody th,
    table.dataTable.cell-border tbody td {
        border-left: 1px solid #ddd;
        border-right: inherit;
    }

    table.dataTable.cell-border tbody tr th:first-child,
    table.dataTable.cell-border tbody tr td:first-child {
        border-right: 1px solid #ddd;
        border-left: inherit;
    } 

    table.dataTable th.dt-left,
    table.dataTable td.dt-left {
        text-align: right;
    }

    table.dataTable th.dt-right,
    table.dataTable td.dt-right {
        text-align: left;
    }

    table.dataTable thead th.dt-head-left,
    table.dataTable thead td.dt-head-left {
        text-align: right;
    }

    table.dataTable tfoot th.dt-head-left,
    table.dataTable tfoot td.dt-head-left {
        text-align: right;
    }

    table.dataTable thead th.dt-head-right,
    table.dataTable thead td.dt-head-right {
        text-align: left;
    }

    table.dataTable tfoot th.dt-head-right,
    table.dataTable tfoot td.dt-head-right {
        text-align: left;
    }

    table.dataTable tbody th.dt-body-left,
    table.dataTable tbody td.dt-body-left {
        text-align: right;
    }

    table.dataTable tbody th.dt-body-right,
    table.dataTable tbody td.dt-body-right {
        text-align: left;
    }

    .dataTables_wrapper .dataTables_length {
        float: right;
    }

    .dataTables_wrapper .dataTables_filter {
        float: left;
        text-align: left;
    }

    .dataTables_wrapper .dataTables_filter input {
        margin-right: 3px;
        margin-left: inherit;
    }

    .dataTables_wrapper .dataTables_info {
        float: right;
    }

    .dataTables_wrapper .dataTables_paginate {
        float: left;
        text-align: left;
    }

    .dataTables_wrapper .dataTables_paginate .paginate_button {
        margin-right: 2px;
        margin-left: inherit;
    }

    .dataTables_wrapper .dataTables_processing {
        right: 50%;
        left: inherit;
        margin-right: -50%;
        margin-left: inherit;
    }

    .ff_fileupload_wrap .ff_fileupload_dropzone_tools {
        left: 10px;
        right: inherit;
    }

    .ff_fileupload_wrap .ff_fileupload_recordvideo_preview {
        left: 60px;
        right: inherit;
    }

    .ff_fileupload_wrap table.ff_fileupload_uploads td.ff_fileupload_actions button.ff_fileupload_start_upload {
        margin-left: 0.5em;
        margin-right: inherit;
    }

    .ff_fileupload_wrap table.ff_fileupload_uploads td.ff_fileupload_actions {
        text-align: left;
    }

    @media (max-width: 420px) {

        .ff_fileupload_wrap table.ff_fileupload_uploads td.ff_fileupload_summary {
            padding-left: 0;
            padding-right: inherit;
        }
    }

    .ff_fileupload_dialog_background {
        right: 0;
        left: inherit;
    }

    .ff_fileupload_dialog_main {
        right: 10%;
        left: inherit;
    }

    .dropify-wrapper .dropify-clear {
        left: 10px;
        right: inherit;
    }

    .dropify-wrapper .dropify-preview .dropify-render i {
        right: 50%;
        left: inherit;
    }

    .dropify-wrapper .dropify-preview .dropify-render .dropify-extension {
        right: 50%;
        left: inherit;
    }
    .dropify-wrapper .dropify-preview .dropify-infos .dropify-infos-inner p.dropify-infos-message::before {
        right: 50%;
        left: inherit;
    }

    .dropify-wrapper.touch-fallback .dropify-preview .dropify-render .dropify-font-file {
        right: 0;
        left: inherit;
    }

    .dropify-wrapper.touch-fallback .dropify-preview .dropify-infos .dropify-infos-inner {
        padding: 5px 0 5px 90px;
    }

    .dropify-wrapper.touch-fallback .dropify-preview .dropify-infos .dropify-infos-inner p {
        text-align: right;
    }

    .dropify-wrapper .dropify-loader {
        left: 15px;
        right: inherit;
    }

    .dropify-wrapper .dropify-errors-container {
        text-align: right;
    }

    .dropify-wrapper .dropify-errors-container ul {
        right: 0;
        left: inherit;
    }

    .dropify-wrapper .dropify-errors-container ul li {
        margin-right: 20px;
        margin-left: inherit;
    }

    .dropify-wrapper~.dropify-errors-container ul li {
        margin-right: 20px;
        margin-left: inherit;
    }

    .sw-main .sw-toolbar {
        margin-right: 0;
        margin-left: inherit;
    }

    .sw-theme-default .step-content {
        text-align: right;
    }

    .sw-theme-default>ul.step-anchor>li {
        margin-left: 2px;
        margin-right: inherit;
    }

    .sw-theme-default>ul.step-anchor>li>a::after {
        right: 0;
        left: inherit;
    }

    .sw-btn-next {
        margin-left: 10px;
        margin-right: inherit;
    }

    .sw-loading::after {
        right: 0;
        left: inherit;
    }

    .sw-loading::before {
        right: 50%;
        left: inherit;
        margin-right: -40px;
        margin-left: inherit;
    }

    .sw-theme-dots .step-content {
        text-align: right;
    }

    .sw-theme-dots>ul.step-anchor:before {
        right: 20px;
        left: inherit;
    }

    .sw-theme-dots>ul.step-anchor>li>a:before {
        right: 37%;
        left: inherit;
    }

    .sw-theme-dots>ul.step-anchor>li>a:after {
        right: 38%;
        left: inherit;
    }

    @media screen and (max-width: 480px) {
        .sw-theme-dots>ul.step-anchor:before {
            right: 19px;
            left: 0;
            margin-left: 10px;
            margin-right: 0;
        }

        .sw-theme-dots>ul.step-anchor>li {
            margin-right: 20px;
            margin-left: inherit;
        }

        .sw-theme-dots>ul.step-anchor>li>a {
            text-align: right;
            margin-right: 0;
            margin-left: inherit;
        }

        .sw-theme-dots>ul.step-anchor>li>a:before {
            right: -14px;
            left: 0;
            margin-left: 10px;
            margin-right: 0;
        }

        .sw-theme-dots>ul.step-anchor>li>a:after {
            right: -33px;
            left: 0;
            margin-left: 10px;
            margin-right: 0;
        }
    }

    .sl-wrapper .sl-close {
        left: 30px;
        right: inherit;
        margin-left: -14px;
        margin-right: inherit;
    }

    .sl-wrapper .sl-counter {
        right: 30px;
        left: inherit;
    }

    .sl-wrapper .sl-navigation button.sl-next {
        left: 5px;
        right: inherit;
    }

    .sl-wrapper .sl-navigation button.sl-prev {
        right: 5px;
        left: inherit;
    }

    .sl-wrapper .sl-image .sl-download {
        left: 5px;
        right: inherit;
    }
    @media (min-width: 35.5em) {

        .sl-wrapper .sl-navigation button.sl-next {
            left: 10px;
            right: inherit;
        }

        .sl-wrapper .sl-navigation button.sl-prev {
            right: 10px;
            left: inherit;
        }
    }

    @media (min-width: 50em) {

        .sl-wrapper .sl-navigation button.sl-next {
            left: 20px;
            right: inherit;
        }

        .sl-wrapper .sl-navigation button.sl-prev {
            right: 20px;
            left: inherit;
        }
    }

    .sl-spinner {
        right: 50%;
        left: inherit;
        margin: -20px -20px 0 0;
    }

    .ui-helper-zfix {
        right: 0;
        left: inherit;
    }

    .ui-widget-overlay {
        right: 0;
        left: inherit;
    }

    .ui-accordion .ui-accordion-header {
        padding: 0.5em 0.7em 0.5em 0.5em;
    }

    .ui-accordion .ui-accordion-icons {
        padding-right: 2.2em;
        padding-left: inherit;
    }

    .ui-accordion .ui-accordion-icons .ui-accordion-icons {
        padding-right: 2.2em;
        padding-left: inherit;
    }

    .ui-accordion .ui-accordion-header .ui-accordion-header-icon {
        right: 0.5em;
        left: inherit;
    }

    .ui-autocomplete {
        right: 0;
        left: inherit;
    }

    .ui-button {
        margin-left: 0.1em;
        margin-right: inherit;
    }

    .ui-button-text-icon-primary .ui-button-text,
    .ui-button-text-icons .ui-button-text {
        padding: 0.4em 2.1em 0.4em 1em;
    }

    .ui-button-text-icon-secondary .ui-button-text {
        padding: 0.4em 1em 0.4em 2.1em;
    }

    .ui-button-icon-only .ui-icon {
        right: 50%;
        left: inherit;
        margin-right: -8px;
        margin-left: inherit;
    }

    .ui-button-text-icon-primary .ui-button-icon-primary,
    .ui-button-text-icons .ui-button-icon-primary,
    .ui-button-icons-only .ui-button-icon-primary {
        right: 0.5em;
        left: inherit;
    }

    .ui-button-text-icon-secondary .ui-button-icon-secondary,
    .ui-button-text-icons .ui-button-icon-secondary,
    .ui-button-icons-only .ui-button-icon-secondary {
        left: 0.5em;
        right: inherit;
    }

    .ui-buttonset {
        margin-left: 7px;
        margin-right: inherit;
    }

    .ui-buttonset .ui-button {
        margin-right: 0;
        margin-left: -0.3em;
    }

    .ui-datepicker .ui-datepicker-prev {
        right: 2px;
        left: inherit;
    }

    .ui-datepicker .ui-datepicker-next {
        left: 2px;
        right: inherit;
    }

    .ui-datepicker .ui-datepicker-prev-hover {
        right: 1px;
        left: inherit;
    }

    .ui-datepicker .ui-datepicker-next-hover {
        left: 1px;
        right: inherit;
    }

    .ui-datepicker .ui-datepicker-prev span,
    .ui-datepicker .ui-datepicker-next span {
        right: 50%;
        left: inherit;
        margin-right: -8px;
        margin-left: inherit;
    }

    .ui-datepicker td span,
    .ui-datepicker td a {
        text-align: left;
    }

    .ui-datepicker .ui-datepicker-buttonpane button.ui-datepicker-current {
        float: right;
    }

    .ui-datepicker-multi .ui-datepicker-group {
        float: right;
    }

    .ui-datepicker-multi .ui-datepicker-group-last .ui-datepicker-header,
    .ui-datepicker-multi .ui-datepicker-group-middle .ui-datepicker-header {
        border-right-width: 0;
        border-left-width: inherit;
    }

    .ui-datepicker-multi .ui-datepicker-buttonpane {
        clear: right;
    }

    .ui-dialog {
        right: 0;
        left: inherit;
    }

    .ui-dialog .ui-dialog-title {
        float: right;
    }

    .ui-dialog .ui-dialog-titlebar-close {
        left: 0.3em;
        right: inherit;
    }

    .ui-dialog .ui-dialog-buttonpane {
        text-align: right;
        padding: 0.3em 0.4em 0.5em 1em;
    }

    .ui-dialog .ui-dialog-buttonpane .ui-dialog-buttonset {
        float: left;
    }

    .ui-dialog .ui-dialog-buttonpane button {
        margin: 0.5em 0 0.5em 0.4em;
    }

    .ui-dialog .ui-resizable-se {
        left: -5px;
        right: inherit;
    }

    .ui-menu .ui-menu-item {
        padding: 3px 0.4em 3px 1em;
    }

    .ui-menu-icons .ui-menu-item {
        padding-right: 2em;
        padding-left: inherit;
    }

    .ui-menu .ui-icon {
        right: 0.2em;
        left: inherit;
    }

    .ui-menu .ui-menu-icon {
        right: auto;
        left: 0;
    }

    .ui-progressbar {
        text-align: right;
    } 

    .ui-resizable-n {
        right: 0;
        left: inherit;
    }

    .ui-resizable-s {
        right: 0;
        left: inherit;
    }

    .ui-resizable-e {
        left: -5px;
        right: inherit;
    }

    .ui-resizable-w {
        right: -5px;
        left: inherit;
    }

    .ui-resizable-se {
        left: 1px;
        right: inherit;
    }

    .ui-resizable-sw {
        right: -5px;
        left: inherit;
    }

    .ui-resizable-nw {
        right: -5px;
        left: inherit;
    }

    .ui-resizable-ne {
        left: -5px;
        right: inherit;
    }

    .ui-selectmenu-menu {
        right: 0;
        left: inherit;
    }

    .ui-selectmenu-button span.ui-icon {
        left: 0.5em;
        right: auto;
    }

    .ui-selectmenu-button span.ui-selectmenu-text {
        text-align: right;
        padding: 0.4em 1em 0.4em 2.1em;
    }

    .ui-slider {
        text-align: right;
    }

    .ui-slider-horizontal .ui-slider-handle {
        margin-right: -0.6em;
        margin-left: -0.6em;
    }

    .ui-slider-horizontal .ui-slider-range-min {
        right: 0;
        left: inherit;
    }

    .ui-slider-horizontal .ui-slider-range-max {
        left: 0;
        right: inherit;
    }

    .ui-slider-vertical .ui-slider-handle {
        right: -0.3em;
        left: inherit;
        margin-right: 0;
        margin-left: inherit;
    }

    .ui-slider-vertical .ui-slider-range {
        right: 0;
        left: inherit;
    }

    .ui-spinner-input {
        margin-right: 0.4em;
        margin-left: 22px;
    }

    .ui-spinner-button {
        left: 0;
        right: inherit;
    }

    /* more specificity required here to override default borders */
    .ui-spinner a.ui-spinner-button {
        border-left: none;
        border-right: inherit;
    }

    .ui-spinner .ui-icon {
        right: 0;
        left: inherit;
    }

    .ui-tabs .ui-tabs-nav li {
        float: right;
        margin: 1px 0 0 0.2em;
    }

    .ui-tabs .ui-tabs-nav .ui-tabs-anchor {
        float: right;
    }

    .ui-widget-shadow {
        margin: 8px 8px 0 0;
    }

    .flexdatalist-results {
        right: 0;
        left: inherit;
    }

    .flexdatalist-results li .group-item-count {
        padding-right: 10px;
        padding-left: inherit;
    }

    .flexdatalist-multiple li {
        float: right;
    }

    .flexdatalist-multiple li.input-container {
        padding: 0 4px 0 0;
    }

    .flexdatalist-multiple li.input-container input {
        padding: 0 4px 0 0;
    }

    .flexdatalist-multiple li.value {
        padding: 2px 7px 2px 25px;
    }

    .flexdatalist-multiple li.value span.fdl-remove {
        left: 0;
        right: inherit;
    }

    .jqvmap-zoomin,
    .jqvmap-zoomout {
        right: 10px;
        left: inherit;
    }

    #growls-default {
        left: 10px;
        right: inherit;
    }

    #growls-tl {
        right: 10px;
        left: inherit;
    }

    #growls-tr {
        left: 10px;
        right: inherit;
    }

    #growls-bl {
        right: 10px;
        left: inherit;
    }

    #growls-br {
        left: 10px;
        right: inherit;
    }

    #growls-cc {
        right: 50%;
        left: inherit;
        margin-right: -125px;
        margin-left: inherit;
    }

    #growls-cl {
        right: 10px;
        left: inherit;
    }

    #growls-cr {
        left: 10px;
        right: inherit;
    }

    .growl .growl-close {
        float: left;
    }

    #ui_notifIt {
        left: 10px;
        right: inherit;
    }

    #ui_notifIt.info {
        right: 10px;
        left: inherit;
    }

    #notifIt_close {
        left: 0;
        right: inherit;
    }

    .notifit_confirm_bg,
    .notifit_prompt_bg {
        right: 0;
        left: inherit;
    }

    .notifit_confirm,
    .notifit_prompt {
        right: 0;
        left: inherit;
    }

    .owl-carousel .owl-video-play-icon {
        right: 50%;
        left: inherit;
        margin-right: -40px;
        margin-left: inherit;
    }

    .owl-controls .owl-page.active:after,
    .owl-controls .owl-page.active:before {
        right: 50%;
        left: inherit;
    }

    .owl-controls .owl-page.active:after {
        margin-right: -14px;
        margin-left: inherit;
    }

    .owl-controls .owl-page.active:before {
        margin-right: -18px;
        margin-left: inherit;
    }

    .ps__rail-y {
        left: 0;
        right: inherit !important;
    }

    .ps__thumb-y {
        left: 2px;
        right: inherit;
    }

    .select2-container .select2-selection--single .select2-selection__rendered {
        padding-right: 8px;
        padding-left: 20px;
    }

    .select2-container .select2-selection--multiple .select2-selection__rendered {
        padding-right: 8px;
        padding-left: inherit;
    }

    .select2-container .select2-search--inline {
        float: right;
    }

    .select2-results__option {
        padding: 10px 8px 10px 20px;
    }

    .select2-close-mask {
        right: 0;
        left: inherit;
    }

    .select2-container--default .select2-selection--single .select2-selection__clear {
        float: left;
    }

    .select2-container--default .select2-selection--single .select2-selection__arrow {
        left: 1px;
        right: inherit;
    }

    .select2-container--default .select2-selection--single .select2-selection__arrow b {
        right: 50%;
        left: initial;
        margin-right: -12px;
        margin-left: inherit;
    }

    .select2-container--default .select2-selection--multiple .select2-selection__placeholder {
        float: right;
    }

    .select2-container--default .select2-selection--multiple .select2-selection__clear {
        float: left;
        margin-left: 10px;
        margin-right: inherit;
    }

    .select2-container--default .select2-selection--multiple .select2-selection__choice {
        float: right;
        margin-left: 5px;
        margin-right: inherit;
    }

    .select2-container--default .select2-selection--multiple .select2-selection__choice__remove {
        margin-left: 2px;
        margin-right: inherit;
    }

    .select2-container--default .select2-results__option .select2-results__option {
        padding-right: 1em;
        padding-left: inherit;
    }

    .select2-container--default .select2-results__option .select2-results__option .select2-results__group {
        padding-right: 0;
        padding-left: inherit;
    }

    .select2-container--default .select2-results__option .select2-results__option .select2-results__option {
        margin-right: -1em;
        margin-left: inherit;
        padding-right: 2em;
        padding-left: inherit;
    }

    .select2-container--default .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
        margin-right: -2em;
        margin-left: inherit;
        padding-right: 3em;
        padding-left: inherit;
    }

    .select2-container--default .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
        margin-right: -3em;
        margin-left: inherit;
        padding-right: 4em;
        padding-left: inherit;
    }

    .select2-container--default .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
        margin-right: -4em;
        margin-left: inherit;
        padding-right: 5em;
        padding-left: inherit;
    }

    .select2-container--default .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
        margin-right: -5em;
        margin-left: inherit;
        padding-right: 6em;
        padding-left: inherit;
    }

    .select2-container--classic .select2-selection--single .select2-selection__clear {
        float: left;
    }

    .select2-container--classic .select2-selection--single .select2-selection__arrow {
        border-right: 1px solid $border;
        border-left: inherit;
        border-top-left-radius: 4px;
        border-top-right-radius: inherit;
        border-bottom-left-radius: 4px;
        border-bottom-right-radius: inherit;
        left: 1px;
        right: inherit;
    }

    .select2-container--classic .select2-selection--single .select2-selection__arrow b {
        right: 50%;
        left: inherit;
        margin-right: -4px;
        margin-left: inherit;
    }

    .select2-container--classic .select2-selection--multiple .select2-selection__choice {
        float: right;
        margin-left: 5px;
        margin-right: inherit;
    }

    .select2-container--classic .select2-selection--multiple .select2-selection__choice__remove {
        margin-left: 2px;
        margin-right: inherit;
    }

    .select2-dropdown.select2-dropdown--below {
        direction: rtl;
    }

    .note-icon-pull-left {
        float: right;
    }

    .note-icon-pull-right {
        float: left;
    }

    .note-icon.note-icon-pull-left {
        margin-left: 0.3em;
        margin-right: inherit;
    }

    .note-icon.note-icon-pull-right {
        margin-right: 0.3em;
        margin-left: inherit;
    }

    .note-editor .note-editing-area .note-editable img.note-float-left {
        margin-left: 10px;
        margin-right: inherit;
    }

    .note-editor .note-editing-area .note-editable img.note-float-right {
        margin-right: 10px;
        margin-left: inherit;
    }

    .note-editor.note-airframe.fullscreen,
    .note-editor.note-frame.fullscreen {
        right: 0;
        left: inherit;
    }

    .note-editor.note-airframe .note-status-output .pull-right,
    .note-editor.note-frame .note-status-output .pull-right {
        float: left !important;
    }

    .note-editor.note-airframe .note-status-output .alert .note-icon,
    .note-editor.note-frame .note-status-output .alert .note-icon {
        margin-left: 5px;
        margin-right: inherit;
    }

    .note-popover.popover .arrow {
        right: 20px !important;
        left: inherit !important;
    }

    .note-editor .note-toolbar,
    .note-popover .popover-content {
        padding: 0 5px 5px 0;
    }

    .note-editor .note-toolbar>.note-btn-group,
    .note-popover .popover-content>.note-btn-group {
        margin-right: 0;
        margin-left: 5px;
    }

    .note-editor .note-toolbar .note-color .dropdown-toggle,
    .note-popover .popover-content .note-color .dropdown-toggle {
        padding-right: 5px;
        padding-left: inherit;
    }

    .note-editor .note-toolbar .note-para .note-dropdown-menu>div+div,
    .note-popover .popover-content .note-para .note-dropdown-menu>div+div {
        margin-right: 5px;
        margin-left: inherit;
    }

    .note-editor .note-toolbar .note-dropdown-menu.right,
    .note-popover .popover-content .note-dropdown-menu.right {
        left: 0;
        right: auto;
    }

    .note-editor .note-toolbar .note-dropdown-menu.right:before,
    .note-popover .popover-content .note-dropdown-menu.right:before {
        left: 9px !important;
        right: auto !important;
    }

    .note-editor .note-toolbar .note-dropdown-menu.right:after,
    .note-popover .popover-content .note-dropdown-menu.right:after {
        left: 10px !important;
        right: auto !important;
    }

    .note-handle .note-control-selection .note-control-nw {
        right: -5px;
        left: inherit;
        border-left: none;
        border-right: inherit;
    }

    .note-handle .note-control-selection .note-control-ne {
        left: -5px;
        right: inherit;
        border-right: none;
        border-left: inherit;
    }

    .note-handle .note-control-selection .note-control-sw {
        right: -5px;
        left: inherit;
    }

    .note-handle .note-control-selection .note-control-se {
        left: -5px;
        right: inherit;
    }

    .note-handle .note-control-selection .note-control-se.note-control-holder {
        border-right: none;
        border-left: inherit;
    }

    .note-handle .note-control-selection .note-control-selection-info {
        left: 0;
        right: inherit;
    }

    .note-editor .btn-group .btn+.btn-group {
        border-right: 0px solid rgba(0, 0, 0, 0.08) !important;
        border-left: inherit !important;
    }

    .sweet-alert {
        right: 50%;
        left: inherit;
        margin-right: -256px;
        margin-left: inherit;
    }

    .sweet-alert .sa-error-container .icon {
        margin-left: 3px;
        margin-right: inherit;
    }

    .sweet-alert .sa-input-error {
        left: 26px;
        right: inherit;
    }

    .sweet-alert .sa-input-error::before,
    .sweet-alert .sa-input-error::after {
        right: 50%;
        left: inherit;
        margin-right: -9px;
        margin-left: inherit;
    }

    .sweet-alert .la-ball-fall {
        right: 50%;
        left: inherit;
        margin-right: -27px;
        margin-left: inherit;
    }

    .sweet-alert .sa-icon.sa-info::before {
        right: 50%;
        left: inherit;
        margin-right: -2px;
        margin-left: inherit;
    }

    .sweet-alert .sa-icon.sa-info::after {
        margin-right: -3px;
        margin-left: inherit;
    }

    @-webkit-keyframes animateSuccessTip {
        0% {
            right: 1px;
            left: inherit;
        }

        54% {
            right: 1px;
            left: inherit;
        }

        70% {
            right: -8px;
            left: inherit;
        }

        84% {
            right: 21px;
            left: inherit;
        }

        100% {
            right: 14px;
            left: inherit;
        }
    }

    @keyframes animateSuccessTip {
        0% {
            right: 1px;
            left: inherit;
        }

        54% {
            right: 1px;
            left: inherit;
        }

        70% {
            right: -8px;
            left: inherit;
        }

        84% {
            right: 21px;
            left: inherit;
        }

        100% {
            right: 14px;
            left: inherit;
        }
    }

    @-webkit-keyframes animateSuccessLong {
        0% {
            left: 46px;
            right: inherit;
        }

        65% {
            left: 46px;
            right: inherit;
        }

        84% {
            left: 0px;
            right: inherit;
        }

        100% {
            left: 8px;
            right: inherit;
        }
    }

    @keyframes animateSuccessLong {
        0% {
            left: 46px;
            right: inherit;
        }

        65% {
            left: 46px;
            right: inherit;
        }

        84% {
            left: 0px;
            right: inherit;
        }

        100% {
            left: 8px;
            right: inherit;
        }
    } 

    .tab_wrapper>ul li {
        float: left;
        margin: 0 0 0 5px;
    }

    .tab_wrapper>ul li.active:after {
        right: 0;
        left: inherit;
    }

    .tab_wrapper .controller {
        left: 0;
        right: inherit;
    }

    .tab_wrapper .controller span.next {
        float: left;
        margin-right: 10px;
        margin-left: inherit;
    }

    .tab_wrapper .content_wrapper {
        float: right;
    }

    .tab_wrapper .content_wrapper .accordian_header.active:after {
        right: 0;
        left: inherit;
    }

    .tab_wrapper .content_wrapper .accordian_header .arrow {
        float: left;
        border-right: 3px solid #ccc;
        border-left: inherit;
        transform: rotate(135deg);
    }

    .tab_wrapper.left_side>ul {
        float: right;
    }

    .tab_wrapper.left_side>ul li:after {
        right: 0;
        left: inherit;
    }

    .tab_wrapper.left_side>ul li.active:after {
        right: 0;
        left: inherit;
    }

    .tab_wrapper.left_side>ul li.active:before {
        left: -1px;
        right: inherit;
    }

    .tab_wrapper.left_side .content_wrapper {
        float: right;
    }

    .tab_wrapper.right_side>ul {
        float: left;
    }

    .tab_wrapper.right_side>ul li {
        text-align: left;
    }

    .tab_wrapper.right_side>ul li:after {
        left: 0;
        right: inherit;
    }

    .tab_wrapper.right_side>ul li.active:after {
        left: 0;
        right: inherit;
    }

    .tab_wrapper.right_side>ul li.active:before {
        right: -2px;
        left: inherit;
    }

    .tab_wrapper.right_side .content_wrapper {
        float: right;
        border: 1px solid #dfe0ef;
        border-left: 0;
    }

    .tab_wrapper .active_tab {
        padding-left: 50px;
        padding-right: inherit;
    }

    .tab_wrapper .active_tab .arrow {
        left: 6px;
        right: inherit;
    }

    .tab_wrapper .active_tab .arrow:after {
        border-left: 3px solid #000;
        border-right: inherit;
        right: 50%;
        left: inherit;
        margin-right: -6px;
        margin-left: inherit;
    }

    @media only screen and (max-width: 768px) {
        .tab_wrapper.show-as-dropdown>.tab_list:before {
            right: 50%;
            left: inherit;
            margin-right: -7px;
            margin-left: inherit;
        }
    } 

    .ui-timepicker-duration {
        margin-right: 5px;
        margin-left: inherit;
    }

    .ui-timepicker-list li {
        padding: 3px 5px 3px 0;
    }

    .timeline .time-show {
        margin-left: -75px;
        margin-right: inherit;
    }

    .timeline:before {
        right: 50%;
        left: inherit;
    }

    .timeline .timeline-icon {
        right: -54px;
        left: inherit;
    }

    .timeline-item .timeline-desk .arrow {
        border-left: 8px solid #ffffff !important;
        border-right: inherit !important;
        right: -7px;
        left: inherit;
    }


    .timeline-item.alt .timeline-desk .arrow-alt {
        border-right: 8px solid #ffffff !important;
        border-left: inherit !important;
        right: auto;
        left: -7px;
    }

    .timeline-item.alt .timeline-desk .album {
        float: left;
    }

    .timeline-item.alt .timeline-desk .album a {
        float: left;
        margin-right: 5px;
        margin-left: inherit;
    }

    .timeline-item.alt .timeline-icon {
        right: auto;
        left: -57px;
    }

    .timeline-item.alt .panel {
        margin-right: 0;
        margin-left: 45px;
    }

    .timeline-item.alt h4,
    .timeline-item.alt p,
    .timeline-item.alt .timeline-date {
        text-align: left;
    }

    .timeline-desk .panel {
        margin-right: 45px;
        margin-left: inherit;
        text-align: right;
    }

    .timeline-desk .album a {
        float: right;
        margin-left: 5px;
        margin-right: inherit;
    }

    .timelineleft:before {
        right: 31px;
        left: inherit;
    }

    .timelineleft>li {
        margin-left: 10px;
        margin-right: inherit;
    }

    .timelineleft>li>.timelineleft-item {
        margin-right: 60px;
        margin-left: 15px;
    }

    .timelineleft>li>.timelineleft-item>.time {
        float: left;
    }

    .timelineleft>li>.fa,
    .timelineleft>li>.glyphicon,
    .timelineleft>li>.ion {
        right: 18px;
        left: inherit;
    }

    #main {
        text-align: right;
    }

    .richText .richText-toolbar ul li {
        float: right;
    }

    .richText .richText-toolbar ul li a .richText-dropdown-outer .richText-dropdown .richText-dropdown-close {
        left: -23px;
        right: inherit;
    }

    .richText .richText-toolbar ul li a .richText-dropdown-outer ul.richText-dropdown li.inline {
        float: right;
    }

    .richText .richText-editor {
        border-right: #dfe0ef solid 2px;
        border-left: inherit;
    }

    .richText .richText-editor:focus {
        border-right: #2b88ff solid 2px;
        border-left: inherit;
    }

    .richText .richText-help {
        float: left;
    }

    .richText .richText-undo,
    .richText .richText-redo {
        float: right;
        border-left: #EFEFEF solid 1px;
        border-right: inherit;
    }

    .richText .richText-list.list-rightclick {
        border-left: #EFEFEF solid 1px;
        border-right: inherit;
    }

    .apexcharts-legend-marker {
        margin-left: 3px;
    }

    .tab_wrapper .content_wrapper .accordian_header.active .arrow {
        border-right: 3px solid $primary;
        border-left: 0;
        transform: rotate(-45deg);
    }


}


// admin-custom //

.rtl {

    .app-header1.header {
        padding-right: 100px;
        padding-left: inherit;
            
        .header-brand {
            margin-left: 0;
            margin-right: inherit;
        }
    }

    &.sidenav-toggled{
        .app-header1.header{
            padding-right: 20px;
            padding-left: inherit;
        }
    } 

    .nav-search {
        padding-left: 15px !important;
        padding-right: inherit !important;

        .form-control.header-search {
            border-left: 0 !important;
            border-right: 1px solid #dee2e6 !important;
            padding-right: 20px;
            padding-left: 0;
            margin-left: -6px;
            margin-right: inherit;
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 7px;
            border-top-left-radius: 0;
            border-top-right-radius: 7px;
        }
    }

    .form-inline {
        .form-control {
            border: 1px solid #dee2e6 !important;
            border-left: 0;
            padding-right: 20px;
            padding-left: 0;
            margin-left: -6px;
            margin-right: inherit;
            border-bottom-left-radius: 0;
            border-bottom-right-radius: inherit;
            border-top-left-radius: 0;
            border-top-right-radius: inherit;
            &:focus,
            &:hover {
                border: 1px solid #dee2e6 !important;
                border-left: 0 !important;
            }
        }
        .btn {
            border-radius: 0;
            border-bottom-left-radius: 7px;
            border-top-left-radius: 7px;
            border-right: 0 !important;
            border-left: 1px solid #dee2e6 !important;
        }
    }

    .navbar-nav .input-icon-addon {
        left: 8px;
        right: inherit;
    }

    .navbar .form-inline .btn {
        border-bottom-left-radius: 7px;
        border-bottom-right-radius: inherit;
        border-top-left-radius: 7px;
        border-top-right-radius: inherit;
    }

    @media (max-width: 991px) {
        .form-inline .nav-search {
            .btn {
                border-radius: 7px 0 0 7px;
            }
            right: 0;
            left: 24px;
            .form-control {
                float: right;
            }
            .btn {
                border-radius: 7px 0 0 7px;
            }
        }
    }

    @media (max-width: 414px) {
        .nav-link.navsearch-icon {
            padding-right: 10px;
            padding-left: inherit;
        }
    }

    .app-content .page-header {
            
        .breadcrumb-item+.breadcrumb-item {
            padding-right: 0; 
            padding-left: inherit; 
        }
    }

    .user-tabs ul li {
        &:first-child {
            border-right: 0;
            border-left: inherit;
        }
    }

    @media (max-width: 767px) {
        #count-down-1 {
            .clock-presenter {
                float: right;
            }
            .hours_dash {
                border-left: none;
                border-right: inherit;
            }
        }
    }

    @media (max-width: 495px) {
        .nested-media {
            .media-body {
                margin-right: 0px;
                margin-left: inherit;
            }
        }
    }
    .header .nav-item .badge, .header .nav-link .badge {
        left: 4px;
        right: inherit;
    }
    .nav-unread.badge {
        left: 0;
        right: inherit;
    }
    .notify-img {
        float: right;
        margin-left: 10px;
        margin-right: inherit;
    }
    .dot-label {
        margin-left: 5px;
        margin-right: inherit;
    }
    .dash3-counter {
        .counter-icon1 {
            border-radius: 0px 20px 0 20px;
        }
    }
    .counter-icon1 {
        border-radius: 20px 0px 20px 0;
    }
    .activity1 {
        margin-left: 20px;
        margin-right: inherit;
    }
    .latest-timeline-1 {
        &:before{
            right: 40px;
            left: inherit;
        }
    }
    .time-activity {
        .br-theme-fontawesome-stars .br-widget a:last-child {
            margin-left: 0;
            margin-right: inherit;
        }
    }
    .activity2 {
        margin-left: 20px;
        margin-right: inherit;
    }

    .header-brand-img {
        margin-left: 0;
        margin-right: inherit;
    }
    @media (min-width: 992px) {
           
        &.sidenav-toggled{
            .app-header1.header{
                padding-right: 70px;
                padding-left: inherit;
            }
        } 
    }
    @media (max-width:767px) {
        &.sidebar-gone.sidenav-toggled {
            .app-sidebar {
                .app-sidebar__user .avatar-status {
                    left: 85px !important;
                    right: inherit !important;
                }
            }
        }
        .responsive-navbar {
            .navbar-collapse {
                    right: 0;
                    left: inherit;
            }
        }
        .navresponsive-toggler{
            margin-left: 1rem;
            margin-right: inherit;
        }
    }
    @media (max-width: 480px) {
        .fc .fc-toolbar-title {
            left: auto !important; 
            right: inherit !important; 
        }
    }
    @media (min-width: 768px) {
        .app-header1.header {
            padding-right: 250px;
            padding-left: inherit;
        }
    }
    .header-grid{
        .drop-icon-wrap .drop-icon-item {
            border-right: 0;
            border-left: 1px solid $border;
            .nav-unread.badge {
                right: 38px;
                left: inherit;
            }
        }
    }
    .pricing-card {
        .ribbon-top-left {
            right: -2px;
            left: inherit;
        }
    }
    .heaader-right{
        .drop-icon-wrap {
            text-align: right;
        }
    }
    .header-user {
        .dropdown-menu{
            a {
                i {
                    &.dropdown-icon {
                        margin-right: 0px;
                        margin-left: 0.5rem;
                    }
                }
            }
        }
    }
    @media  (max-width: 767px) {  
        &.sidebar-gone {
            .app-header1.header {
                padding-right: 0;
                padding-left: inherit;
            }
            &.sidenav-toggled .app-header1.header {
                padding-right: 0;
                padding-left: inherit;
            }
        }
    }
    @media  (min-width: 768px) and (max-width:991px) {  
        &.sidebar-gone {
            &.sidenav-toggled .app-header1.header {
                padding-right: 70px;
                padding-left: inherit;
            }
        }
    }
    #headerMenuCollapse1 ,#headerMenuCollapse2 ,#headerMenuCollapse3,#headerMenuCollapse4{
        .nav-tabs .nav-link {
            margin-right: 5px;
            margin-left: inherit;
        }
    }
    .bootstrap-tagsinput {
        .badge [data-role="remove"] {
            margin-right: 8px;
            margin-left: inherit;
        }
    }
}


// sidemenu //

.rtl  {

    @media (min-width: 768px) {
        &.app.sidenav-toggled .app-content {
            margin-right: 0;
            margin-left: inherit;
        }

        &.app.sidenav-toggled .app-sidebar {
            right: -250px;
            left: inherit;
        }

        &.app.sidebar-mini .footer {
            margin-right: 250px;
            margin-left: inherit;
        }

        &.app.sidebar-mini.sidenav-toggled .footer {
            margin-right: 70px;
            margin-left: inherit;
        }
    }

    @media (max-width: 767px) {

        &.app .app-sidebar {
            right: -250px;
            left: inherit;
        }

        &.app.sidenav-toggled .app-content {
            margin-right: 0;
            margin-left: inherit;
        }

        &.app.sidenav-toggled .app-sidebar {
            right: 0;
            left: inherit;
        }
    }

    .app-content {
        transition: margin-right 0.3s ease;
    }

    @media (min-width: 768px) {
        .app-content {
            margin-right: 250px;
            margin-left: inherit;
        }
    }

    .app-header1 {
        padding-left: 15px;
        padding-right: inherit;
    }

    @media (min-width: 768px) {
        .app-header1 {
            padding-left: 0px;
            padding-right: inherit;
        }
    }

    .app-sidebar__toggle {
        margin: 0 0.5rem 0 0;
        margin-top: 7px;
    }

    @media (max-width: 767px) {
        .app-sidebar__toggle {
            float: left;
        }
    }

    .app-sidebar {
        right: 0;
        left: inherit;
        transition: right 0.3s ease, width 0.3s ease;
    }


    .profile-img {
        left: 70px;
        right: inherit;
    }

    @media (max-width: 767px) {
        &.sidebar-mini.sidenav-toggled .app-sidebar .profile-img {
            left: 78px;
            right: inherit;
        }

        &.sidebar-mini.sidenav-toggled .app-sidebar .app-sidebar__user {
            margin-right: 10px;
            margin-left: inherit;
        }
    }

    .app-sidebar__user-avatar {
        margin-left: 15px;
        margin-right: inherit;
    }

    .side-menu {
        padding: 0 20px 40px 15px;
    }

    .side-menu h3 {
        padding-right: 15px;
        padding-left: inherit;
    }

    .app-sidebar .mCSB_draggerContainer {
        left: -11px;
        right: inherit;
    }

    .side-menu__icon {
        margin-left: 10px;
        margin-right: inherit;
    }

    .slide-menu li:after {
        right: 22px;
        left: inherit;
    }

    .slide-menu li ul a {
        padding: 10px 65px 10px 10px;
    }

    .slide ul ul a {
        padding: 10px 45px 10px 10px !important;
    }

    .slide-menu {
        padding-right: 20px;
        padding: 0;
        margin-right: 12px;
        margin-left: inherit;
    }

    .slide-item {
        padding: 7px 45px 7px 7px;
    }

    .slide-item .icon {
        margin-left: 5px;
        margin-right: inherit;
    }

    .angle {
        margin-left: 10px;
        margin-right: inherit;
    }

    .dropdown-menu.dropdown-menu-right {
        right: auto;
        left: inherit;
    }

    @media (min-width: 768px) {

        &.sidebar-mini.sidenav-toggled .app-content {
            margin-right: 70px;
            margin-left: inherit;
        }

        &.sidebar-mini.sidenav-toggled .app-sidebar {
            right: 0;
            left: inherit;
        }

        &.sidebar-mini.sidenav-toggled .side-menu__label {
            right: 60px;
            left: inherit;
            padding: 12px 20px 12px 5px;
            margin-right: -3px;
            margin-left: inherit;
            border-top-left-radius: 4px;
            border-top-right-radius: inherit;
            border-bottom-left-radius: 4px;
            border-bottom-right-radius: inherit;
        }

        &.sidebar-mini.sidenav-toggled .slide .side-menu__label {
            border-bottom-left-radius: 0;
            border-bottom-right-radius: inherit;
        }

        &.sidebar-mini.sidenav-toggled .slide-menu {
            border-bottom-left-radius: 4px;
            border-bottom-right-radius: inherit;
        }
    }

    @media (min-width: 768px) {

        &.sidebar-mini.sidenav-toggled.sidenav-toggled1 .app-sidebar .side-menu__label {
            right: auto;
            left: inherit;
            margin-right: -5px;
            margin-left: inherit;
        }

        &.sidebar-mini.sidenav-toggled.sidenav-toggled1 .app-sidebar .profile-img {
            left: 80px;
            right: inherit;
        }

        &.sidebar-mini.sidenav-toggled.sidenav-toggled1 .app-sidebar .app-sidebar__user .avatar-status {
            left: 95px;
            right: inherit;
        }
    }

    .app-sidebar .app-sidebar__user .avatar-status {
        left: 85px;
        right: inherit;
    }

    &.app.sidenav-toggled .app-sidebar .app-sidebar__user .avatar-status {
        left: 15px;
        right: inherit;
    }

    .app-sidebar .app-sidebar__user .profile-status:before {
        left: -6px;
        right: inherit;
    }

    @media (min-width: 768px) {
        &.sidebar-mini.sidenav-toggled.sidenav-toggled1 .side-menu__item .side-menu__icon {
            margin-left: 10px;
            margin-right: inherit;
        }
    }

    @media (max-width: 767px) {
        &.sidenav-toggled.sidenav-toggled1 .sub-slide .side-menu__item.slide-item {
            padding: 7px 45px 7px 7px !important;
        }

        &.sidenav-toggled.sidenav-toggled1 .sub-slide .sub-angle {
            margin-left: 0px !important;
            margin-right: inherit !important;
        }

        &.sidenav-toggled.sidenav-toggled1 .sub-slide2 .sub-angle2 {
            margin-left: 0px !important;
            margin-right: inherit !important;
        }
    }

    .child-sub-menu {
        padding: 10px 26px 10px 0px;
    }

    .child-sub-menu .slide-item {
        padding: 6px 45px 6px 7px !important;
    }

    .child-sub-menu2 {
        padding: 10px 26px 10px 10px;
    }

    .child-sub-menu2 .slide-item {
        padding: 6px 45px 6px 7px !important;
    }

    &.sidenav-toggled.sidenav-toggled1 .sub-slide .side-menu__item.slide-item .side-menu__label {
        right: 50px;
        left: inherit;
    }

    &.sidenav-toggled.sidenav-toggled1 .sub-slide .sub-angle {
        margin-left: 15px;
        margin-right: inherit;
    }

    &.sidenav-toggled.sidenav-toggled1 .sub-slide2 .sub-angle2 {
        margin-left: 15px;
        margin-right: inherit;
    }
}

